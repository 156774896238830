import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";

const useStyles = makeStyles(descriptionStyle);

export default function SectionDescription() {
  const classes = useStyles();
  return (
    <div className={classNames(classes.aboutDescription, classes.textCenter)}>
      <GridContainer>
        <GridItem
          md={9}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h5 className={classes.description}>
            Established in the year 2008, omegabangles.com is India's leading
            destination for high-quality Brass Bangles with strikingly beautiful
            designs. We excel in manufacturing and exporting various types of
            bangles, Earrings, Finger rings, Sikh Kada, Laminado Watches, and
            more. We aim to revolutionize the fine bangles and lifestyle segment
            in India with a firm focus on craftsmanship, quality, and customer
            experience. We house more than 1,500 unique designs for you to
            choose from. All these designs are crafted to perfection with
            high-quality plated colours to suit the client's needs.
            <br />
            <br />
            <br />
            Our manufacturing operation consists of 11 deliberate units spread
            over 3600 sq ft in Karnataka, employing a dedicated workforce of
            over 200 workers having a personal production capacity of 12,00,000
            pieces per annum. With the standard product development team for the
            perfect quality guarantee, & market research and development
            designed for us to be modernized as per international standards. We
            are strongly focused on market expansion, having a track record of
            developing contemporary designs that cater to a client's specific
            requirements across all product categories. In the year 2017, our
            company got the ISO 9001:2015 certificate (Quality Management
            System) for the good quality of the bangles.
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}
