/* eslint-disable */
import React, { useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
import { headApi } from "utils/headApi";
import { useHistory } from "react-router-dom";
import { localApi } from "utils/headApi";
import { IoMdLogOut } from "react-icons/io";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { FaUserEdit, FaExpeditedssl, FaAddressBook } from "react-icons/fa";
import { RiShoppingBagLine } from "react-icons/ri";
import { MdAddchart } from "react-icons/md";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";

// @material-ui/icons
import Search from "@material-ui/icons/Search";
import Apps from "@material-ui/icons/Apps";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import ViewDay from "@material-ui/icons/ViewDay";
import Dns from "@material-ui/icons/Dns";
import Build from "@material-ui/icons/Build";
import ListIcon from "@material-ui/icons/List";
import People from "@material-ui/icons/People";
import Assignment from "@material-ui/icons/Assignment";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import Chat from "@material-ui/icons/Chat";
import Call from "@material-ui/icons/Call";
import ViewCarousel from "@material-ui/icons/ViewCarousel";
import AccountBalance from "@material-ui/icons/AccountBalance";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ArtTrack from "@material-ui/icons/ArtTrack";
import ViewQuilt from "@material-ui/icons/ViewQuilt";
import LocationOn from "@material-ui/icons/LocationOn";
import Fingerprint from "@material-ui/icons/Fingerprint";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Store from "@material-ui/icons/Store";
import AccountCircle from "@material-ui/icons/AccountCircle";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Layers from "@material-ui/icons/Layers";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import LineStyle from "@material-ui/icons/LineStyle";
import Error from "@material-ui/icons/Error";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
import navbarsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/navbarsStyle.js";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(navbarsStyle);

export default function HeaderLinks(props) {
  const [bangle, setbangle] = React.useState([]);
  const [earing, setEaring] = React.useState([]);
  const [ring, setRing] = React.useState([]);
  const [watch, setWatch] = React.useState([]);
  const [kada, setKada] = React.useState([]);
  const [cardList, setCardList] = React.useState([]);

  // const easeInOutQuad = (t, b, c, d) => {
  //   t /= d / 2;
  //   if (t < 1) return (c / 2) * t * t + b;
  //   t--;
  //   return (-c / 2) * (t * (t - 2) - 1) + b;
  // };

  // const smoothScroll = (e, target) => {
  //   if (window.location.pathname === "/sections") {
  //     var isMobile = navigator.userAgent.match(
  //       /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  //     );
  //     if (isMobile) {
  //       // if we are on mobile device the scroll into view will be managed by the browser
  //     } else {
  //       e.preventDefault();
  //       var targetScroll = document.getElementById(target);
  //       scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
  //     }
  //   }
  // };
  // const scrollGo = (element, to, duration) => {
  //   var start = element.scrollTop,
  //     change = to - start,
  //     currentTime = 0,
  //     increment = 20;

  //   var animateScroll = function () {
  //     currentTime += increment;
  //     var val = easeInOutQuad(currentTime, start, change, duration);
  //     element.scrollTop = val;
  //     if (currentTime < duration) {
  //       setTimeout(animateScroll, increment);
  //     }
  //   };
  //   animateScroll();
  // };
  var onClickSections = {};

  const { dropdownHoverColor } = props;
  const classes = useStyles();
  const classe = useStyle();
  const history = useHistory();

  const date = Intl.DateTimeFormat("fr-CA", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  })
    .format(new Date())
    .replaceAll("/", "-");

  const activeSession = sessionStorage.getItem("sessionData");
  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  useEffect(() => {
    const fetchCartListData = async () => {
      try {
        const response = await fetch(localApi + `/clientGroup/allList`);
        const json = await response.json();
        const cList = json.ClientList.filter((f) => f.regId === userObj.id)[0];

        const list =
          localApi +
          `/cart/expireCart/byAdminAndClientIdAndAcitve/${userObj.admin.id}/${cList.id}?active=0`;
        const result = await fetch(list);
        const data = await result.json();
        setCardList(data);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchCartListData();
  }, []);

  useEffect(() => {
    const fetchBanglesData = async () => {
      try {
        const response = await fetch(
          localApi + `/parent/list/byAdminIdAndCategoryId/2/11`
        );
        const json = await response.json();
        setbangle(json);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchBanglesData();
  }, []);

  useEffect(() => {
    const fetchEarRingsData = async () => {
      try {
        const response = await fetch(
          localApi + `/parent/list/byAdminIdAndCategoryId/2/12`
        );
        const json = await response.json();
        setEaring(json);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchEarRingsData();
  }, []);

  useEffect(() => {
    const fetchFingerRingsData = async () => {
      try {
        const response = await fetch(
          localApi + `/parent/list/byAdminIdAndCategoryId/2/13`
        );
        const json = await response.json();
        setRing(json);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchFingerRingsData();
  }, []);

  useEffect(() => {
    const fetchWatchData = async () => {
      try {
        const response = await fetch(
          localApi + `/parent/list/byAdminIdAndCategoryId/2/14`
        );
        const json = await response.json();
        setWatch(json);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchWatchData();
  }, []);

  useEffect(() => {
    const fetchKadaData = async () => {
      try {
        const response = await fetch(
          localApi + `/parent/list/byAdminIdAndCategoryId/2/15`
        );
        const json = await response.json();
        setKada(json);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchKadaData();
  }, []);

  const handleLogOut = () => {
    // const finalURL = localApi + `/otp/logout/registerById/${userObj && userObj.id}`;
    fetch(localApi + `/otp/logout/registerById/${userObj && userObj.id}`, {
      method: "PUT",
      // headers: {
      //   "content-type": "application/json",
      //   accept: "application/json",
      // },
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        if (response.error === true) {
          sessionStorage.removeItem("sessionData");
          history.push("/");
          window.location.reload(false);
        }
      });
  };

  return (
    <List className={classes.list + " " + classes.mlAuto}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Bangles"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={Apps}
          // dropdownList={[]}
          dropdownList={
            bangle &&
            bangle.map((x) => (
              <Link
                to={`/size-mm-list?catdesc=BANGLES&cat1desc=${x.title}&Plating=Gold%20Plating`}
                className={classes.dropdownLink}
                key={x.id}
              >
                {x.title}
              </Link>
            ))
          }
        />
      </ListItem>

      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Earings"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={Apps}
          dropdownList={
            earing &&
            earing.map((x) => (
              <Link
                to={`/size-mm-list?catdesc=EARRINGS&cat1desc=${x.title}&Plating=Gold%20Plating`}
                className={classes.dropdownLink}
                key={x.id}
              >
                {x.title}
              </Link>
            ))
          }
        />
      </ListItem>

      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Finger Ring"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={Apps}
          dropdownList={
            ring &&
            ring.map((x) => (
              <Link
                to={`/size-mm-list?catdesc=FINGER RINGS&cat1desc=${x.title}&Plating=Gold%20Plating`}
                className={classes.dropdownLink}
                key={x.id}
              >
                {x.title}
              </Link>
            ))
          }
        />
      </ListItem>

      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Watch"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={Apps}
          dropdownList={
            watch &&
            watch.map((x) => (
              <Link
                to={`/size-mm-list?catdesc=WATCH&cat1desc=${x.title}&Plating=Gold%20Plating`}
                className={classes.dropdownLink}
                key={x.id}
              >
                {x.title}
              </Link>
            ))
          }
        />
      </ListItem>

      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Punjabi kada"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={Apps}
          dropdownList={
            kada &&
            kada.map((x) => (
              <Link
                to={`/size-mm-list?catdesc=PUNJABI KADA&cat1desc=${x.title}&Plating=Gold%20Plating`}
                className={classes.dropdownLink}
                key={x.id}
              >
                {x.title}
              </Link>
            ))
          }
        />
      </ListItem>
      {/* <ListItem className={classes.listItem}>
          <Button
            href="/todays-deal"
            className={classes.navLink}
            // onClick={(e) => e.preventDefault()}
            color="transparent"
          >
            Todays Deals
          </Button>
        </ListItem> */}
      {!activeSession ? (
        ""
      ) : (
        <span>
          {cardList && cardList.length != 0 ? (
            <ListItem className={classes.listItem}>
              <CustomDropdown
                noLiPadding
                navDropdown
                hoverColor={dropdownHoverColor}
                buttonText="Cart"
                buttonProps={{
                  className: classes.navLink,
                  color: "transparent",
                }}
                buttonIcon={ShoppingCartIcon}
                dropdownList={[
                  <Link
                    key="cart"
                    to="/add-cart"
                    // to={`/cart?byuserid=919967457786&clientid=${
                    //   userObj && userObj.mobile.slice(1)
                    // }&expdate=${date}`}
                    className={classes.dropdownLink}
                  >
                    <MdAddchart className={classes.dropdownIcons} /> Add Cart
                  </Link>,
                ]}
              />
            </ListItem>
          ) : (
            ""
          )}
          <ListItem className={classes.listItem}>
            <CustomDropdown
              noLiPadding
              navDropdown
              hoverColor={dropdownHoverColor}
              buttonText="Section"
              buttonProps={{
                className: classes.navLink,
                color: "transparent",
              }}
              buttonIcon={Apps}
              dropdownList={[
                <Link
                  key="pro"
                  to="/edit-profile"
                  className={classes.dropdownLink}
                >
                  <FaUserEdit className={classes.dropdownIcons} /> Edit Profile
                </Link>,
                // <Link
                //   key="pas"
                //   to="/edit-password"
                //   className={classes.dropdownLink}
                // >
                //   <FaExpeditedssl className={classes.dropdownIcons} /> Generate
                //   Password
                // </Link>,
                <Link to="/add-address" className={classes.dropdownLink}>
                  <FaAddressBook className={classes.dropdownIcons} /> Add
                  Address
                </Link>,
                <Link
                  to="/edit-update-address"
                  className={classes.dropdownLink}
                >
                  <FaAddressBook className={classes.dropdownIcons} /> Update
                  Address
                </Link>,
                <Link to="/todays-deal" className={classes.dropdownLink}>
                  <ShoppingBasketIcon className={classes.dropdownIcons} />
                  Todays Deals
                </Link>,
                <Link key="ad" to="/my-order" className={classes.dropdownLink}>
                  <RiShoppingBagLine className={classes.dropdownIcons} /> My
                  Order
                </Link>,
                <>
                  {activeSession ? (
                    <Link
                      to=""
                      className={classes.dropdownLink}
                      onClick={handleLogOut}
                    >
                      <IoMdLogOut className={classes.dropdownIcons} /> Log Out
                    </Link>
                  ) : (
                    ""
                  )}
                </>,
              ]}
            />
          </ListItem>
        </span>
      )}
      {/* <div className={classe.mlAuto}>
        <CustomInput
          white
          inputRootCustomClasses={classe.inputRootCustomClasses}
          formControlProps={{
            className: classe.formControl,
          }}
          inputProps={{
            placeholder: "Search",
            inputProps: {
              "aria-label": "Search",
              className: classe.searchInput,
            },
          }}
        />
        <Button color="white" justIcon round>
          <Search className={classe.searchIcon} />
        </Button>
        </div> */}
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary",
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
  ]),
};
