import React from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { RiLockPasswordLine } from "react-icons/ri";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles(loginPageStyle);

export default function GeneratePassword() {
    const [values, setValues] = React.useState({
        password: "",
        showPassword: false,
        confirm: "",
        showConfirm: false,
    });
    const [passMatchErr, setMatchErr] = React.useState("");

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handlePasswordChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowConfirm = () => {
        setValues({ ...values, showConfirm: !values.showConfirm });
    };

    const handleMouseDownConfirm = (event) => {
        event.preventDefault();
    };

    const handleConfirmChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    });

    const classes = useStyles();
  const activeSession = sessionStorage.getItem("sessionData");

    const SubmitPassword = () => {
        let errorCount = 0;
        if (values.password !== "undefined" && values.confirm !== "undefined") {
            if (values.password !== values.confirm) {
                setMatchErr("Password Don't Match");
                errorCount++;
            } else {
                setMatchErr("");
            }
        }
        if (errorCount === 0) {
            // console.log(values.password);
            // console.log(values.confirm);
        }
    };

    return (
        <div>
            {activeSession ? <>
            <Helmet>
                <title>Password</title>
            </Helmet>
            <Header
                fixed
                color="transparent"
                brand="Home"
                links={<HeaderLinks dropdownHoverColor="warning" />}
            />
            <div
                className={classes.pageHeader}
            >
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={4}>
                            <Card>
                                <form className={classes.form}>
                                    <CardHeader
                                        color="warning"
                                        signup
                                        className={classes.cardHeader}
                                    >
                                        <h4 className={classes.cardTitle}><RiLockPasswordLine size={27} /> Generate Password</h4>
                                    </CardHeader>
                                    <CardBody signup>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomInput
                                                labelText="Password"
                                                id="password"
                                                inputProps={{
                                                    endAdornment: (
                                                        <InputAdornment
                                                            position="end"
                                                            className={classes.inputAdornment}
                                                        >
                                                            <IconButton
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {values.showPassword ? (
                                                                    <Visibility />
                                                                ) : (
                                                                    <VisibilityOff />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    type: values.showPassword ? "text" : "password",
                                                    placeholder: "Password ...",
                                                    name: "values.password",
                                                    value: values.password,
                                                    onChange: handlePasswordChange("password"),
                                                }}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                            />  
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomInput
                                                labelText="Confirm Password"
                                                id="confirm"
                                                inputProps={{
                                                    endAdornment: (
                                                        <InputAdornment
                                                            position="end"
                                                            className={classes.inputAdornment}
                                                        >
                                                            <IconButton
                                                                onClick={handleClickShowConfirm}
                                                                onMouseDown={handleMouseDownConfirm}
                                                            >
                                                                {values.showConfirm ? (
                                                                    <Visibility />
                                                                ) : (
                                                                    <VisibilityOff />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    type: values.showConfirm ? "text" : "password",
                                                    placeholder: "Confirm Password ...",
                                                    name: "values.confirm",
                                                    value: values.confirm,
                                                    onChange: handleConfirmChange("confirm"),
                                                }}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                required={true}
                                            />
                                            <span style={{ color: "red", marginLeft: "12px" }}>{passMatchErr}</span>
                                        </GridItem>
                                    </CardBody>
                                    <br />
                                    <div className={classes.textCenter}>
                                        <Button simple color="warning" size="lg" onClick={SubmitPassword}>
                                            Save
                                        </Button>
                                    </div>
                                </form>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
            </> : <Redirect to="/" />}
        </div>
    );
}