import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import axios from "axios";
import Close from "@material-ui/icons/Close";
import classNames from "classnames";
import ImageGallery from "react-image-gallery";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import CustomInput from "components/CustomInput/CustomInput.js";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Accordion from "components/Accordion/Accordion.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import Icon from "@material-ui/core/Icon";
import Slide from "@material-ui/core/Slide";
import { FiPhoneCall } from "react-icons/fi";
import Popover from "@material-ui/core/Popover";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import SweetAlert from "react-bootstrap-sweetalert";

import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";
import radio from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import popOver from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import style from "assets/jss/material-kit-pro-react/components/buttonStyle.js";

import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "views/OmegaBangles/Section/OmegaFooter";

const useStyles = makeStyles(productStyle);
const useStyle = makeStyles(radio);
const useStylPop = makeStyles(popOver);
const useStyleAlert = makeStyles(style);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function OrderStock() {
    const [item, setItem] = React.useState([]);
    const [mobile, setMobile] = React.useState("");
    const [mobileErr, setMobileErr] = React.useState("");
    const [otpNumber, setOtpNumber] = React.useState("");
    const [otpNumberErr, setOtpNumberErr] = React.useState("");
    const [otpScreen, setOtpScreen] = React.useState(false);
    const [otp, setOtp] = React.useState("");
    const [cat, setCat] = React.useState("");
    const [catMM, setCatMM] = React.useState("");
    const [plat, setPlat] = React.useState("");
    const [plating, setPlating] = React.useState("");
    const [metOption, setMetOption] = React.useState("");
    const [sets, setSets] = React.useState("");
    const [box, setBox] = React.useState("");
    const [packing, setPacking] = React.useState("");
    const [calculate, setCalculate] = React.useState("");
    const [labourList, setLobourList] = React.useState("");
    const [metal, setMetal] = React.useState("");
    const [selectedEnabled, setSelectedEnabled] = React.useState("a");
    const [anchorElTop, setAnchorElTop] = React.useState(null);
    const [size1x8, setSize1x8] = React.useState("");
    const [size1x10, setSize1x10] = React.useState("");
    const [size1x12, setSize1x12] = React.useState("");
    const [size1x14, setSize1x14] = React.useState("");
    const [size2x0, setSize2x0] = React.useState("");
    const [size2x2, setSize2x2] = React.useState("");
    const [size2x4, setSize2x4] = React.useState("");
    const [size2x6, setSize2x6] = React.useState("");
    const [size2x8, setSize2x8] = React.useState("");
    const [size2x10, setSize2x10] = React.useState("");
    const [size2x12, setSize2x12] = React.useState("");
    const [size2x14, setSize2x14] = React.useState("");
    const [size3x0, setSize3x0] = React.useState("");
    const [indComment, setIndComment] = React.useState("");
    const [usComment, setUsComment] = React.useState("");
    const [size1, setSize1] = React.useState("");
    const [size2, setSize2] = React.useState("");
    const [size3, setSize3] = React.useState("");
    const [size4, setSize4] = React.useState("");
    const [size5, setSize5] = React.useState("");
    const [size6, setSize6] = React.useState("");
    const [size7, setSize7] = React.useState("");
    const [size8, setSize8] = React.useState("");
    const [loginModal, setLoginModal] = React.useState(false);
    const [prodMix, setProdMix] = React.useState("");
    const [submitted, setSubmitted] = React.useState(false);
    const [alertMsg, alertMsgOps] = React.useState("");
    const [alertType, alertTypeOps] = React.useState("");
    const [proTotal, setProTotal] = React.useState("");
    const [stockSize1x8, setStockSize1x8] = React.useState("");
    const [stockSize1x10, setStockSize1x10] = React.useState("");
    const [stockSize1x12, setStockSize1x12] = React.useState("");
    const [stockSize1x14, setStockSize1x14] = React.useState("");
    const [stockSize2x0, setStockSize2x0] = React.useState("");
    const [stockSize2x2, setStockSize2x2] = React.useState("");
    const [stockSize2x4, setStockSize2x4] = React.useState("");
    const [stockSize2x6, setStockSize2x6] = React.useState("");
    const [stockSize2x8, setStockSize2x8] = React.useState("");
    const [stockSize2x10, setStockSize2x10] = React.useState("");
    const [stockSize2x12, setStockSize2x12] = React.useState("");
    const [stockSize2x14, setStockSize2x14] = React.useState("");
    const [stockSize3x0, setStockSize3x0] = React.useState("");

    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
      });

    const classes = useStyles();
    const classe = useStyle();
    const classPop = useStylPop();
    const history = useHistory();
    const classeAlert = useStyleAlert();

    const activeSession = sessionStorage.getItem("sessionData");
    const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

    const handleSendOtp = () => {
        let errorCount = 0;
        if (!mobile || mobile.length < 12) {
            errorCount++;
            setMobileErr("Please Enter 10 digit Mobile Number");
        }
        if (errorCount === 0) {
            fetch(
                headApi + `/user/sendotp?mobile=+${mobile}&type=client&webappid=4`,
                {
                    method: "PUT",
                    headers: {
                        "content-type": "application/json",
                        accept: "application/json",
                    },
                }
            )
                .then((response) => response.json())
                .then((response) => {
                    // console.log(response);
                    if (response.message === "Enter Registerd Mobile number") {
                        setMobileErr("Please Enter Valid Mobile Number");
                    }
                    if (response && response.error === "false") {
                        setOtpScreen({ otpScreen: true });
                        setOtp(response.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleOtp = (e) => {
        setOtpNumber(e.target.value);
        setOtpNumberErr("");
    };

    const handleLogin = () => {
        let errCount = 0;
        if (otpNumber.length !== 6) {
            errCount++;
            setOtpNumberErr("Invalid OTP");
        }

        if (errCount === 0) {
            fetch(
                headApi + `/user/login?otp=${otpNumber}&mobile=+${mobile}&type=client`,
                {
                    method: "PUT",
                    headers: {
                        "content-type": "application/json",
                        accept: "application/json",
                    },
                }
            )
                .then((response) => response.json())
                .then((response) => {
                    if (response.apiResponse.error !== "false") return false;
                    const userObj = response.user;
                    sessionStorage.setItem("sessionData", JSON.stringify(userObj));
                    setLoginModal(false);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleCall = (e) => {
        e.preventDefault();
        fetch(
            headApi + `/user/callMe?mobile=+${mobile}&webappid=4`,
            {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                    accept: "application/json",
                },
            }
        )
            .then((response) => response.json())
            .then((response) => {
                if (response === "Thank You for Visiting! We will reach back to you soon.") {
                    alert("Thank You for Visiting! We will reach back to you soon.");
                }
            });
    };

    const { state } = useLocation();

    async function productGet() {
        const get = headApi + `/products/byid/${state}`;
        const result = await fetch(get);
        const rest = await result.json();
        // console.log(rest);
        const MixId = rest.mixmm ? rest.mixmm.split(",") : "";
        const tempData = [];
        MixId && MixId.forEach(x =>
            axios.get(headApi + `/products/byid/${x}`)
                .then(res => {
                    // console.log(res.data);
                    tempData.push(res.data);
                }))
        setProdMix(tempData);

        setItem(rest);
        setCat(rest.cat);
        setCatMM(rest.banglessizemm);
        setPlat(rest.plating);
        setPlating(rest.plating.description);
        setMetOption(rest.metalOption);
        setSets(rest.sets);
        setBox(rest.box);
        setPacking(rest.packing);
        setCalculate(rest.taxCalculation);
        setLobourList(rest.labourList);
        setMetal(rest.metal);
        setProTotal(rest.total);
        setStockSize1x8(rest.stockSizes._1x8);
        setStockSize1x10(rest.stockSizes._1x10);
        setStockSize1x12(rest.stockSizes._1x12);
        setStockSize1x14(rest.stockSizes._1x14);
        setStockSize2x0(rest.stockSizes._2x0);
        setStockSize2x2(rest.stockSizes._2x2);
        setStockSize2x4(rest.stockSizes._2x4);
        setStockSize2x6(rest.stockSizes._2x6);
        setStockSize2x8(rest.stockSizes._2x8);
        setStockSize2x10(rest.stockSizes._2x10);
        setStockSize2x12(rest.stockSizes._2x12);
        setStockSize2x14(rest.stockSizes._2x14);
        setStockSize3x0(rest.stockSizes._3x0);
        setSize1x8(rest.stockSizes._1x8);
        setSize1x10(rest.stockSizes._1x10);
        setSize1x12(rest.stockSizes._1x12);
        setSize1x14(rest.stockSizes._1x14);
        setSize2x0(rest.stockSizes._2x0);
        setSize2x2(rest.stockSizes._2x2);
        setSize2x4(rest.stockSizes._2x4);
        setSize2x6(rest.stockSizes._2x6);
        setSize2x8(rest.stockSizes._2x8);
        setSize2x10(rest.stockSizes._2x10);
        setSize2x12(rest.stockSizes._2x12);
        setSize2x14(rest.stockSizes._2x14);
        setSize3x0(rest.stockSizes._3x0);
        setSize1(rest.stockSizes._1x10);
        setSize2(rest.stockSizes._1x12);
        setSize3(rest.stockSizes._2x0);
        setSize4(rest.stockSizes._2x4);
        setSize5(rest.stockSizes._2x8);
        setSize6(rest.stockSizes._2x10);
        setSize7(rest.stockSizes._2x12);
        setSize8(rest.stockSizes._3x0);
    }

    useEffect(() => {
        productGet();
        setLoginModal(true);
    }, []);

    const totalPiece = Math.ceil("864" / sets.pcs / box.box);

    const images = [];
    const imgActive = [];
    let val = 1;
    Object.keys(item).map((i) => {
        if (i.includes("design")) {
            // console.log(item[i][`image${val}`]);
            images.push({
                original:
                    imgApi + "" + item[i][`image${val}`] !==
                        imgApi + "" + "/crop_img/null"
                        ? imgApi + "" + item[i][`image${val}`]
                        : "",
                thumbnail:
                    imgApi + "" + item[i][`image${val}`] !==
                        imgApi + "" + "/crop_img/null"
                        ? imgApi + "" + item[i][`image${val}`]
                        : "",
            });
            imgActive.push({ active: item[i][`img${val}active`] })
            val += 1;
        }
    });

    var indianQty =
        parseInt(size1x8 !== "" ? size1x8 : "0") +
        parseInt(size1x10 !== "" ? size1x10 : "0") +
        parseInt(size1x12 !== "" ? size1x12 : "0") +
        parseInt(size1x14 !== "" ? size1x14 : "0") +
        parseInt(size2x0 !== "" ? size2x0 : "0") +
        parseInt(size2x2 !== "" ? size2x2 : "0") +
        parseInt(size2x4 !== "" ? size2x4 : "0") +
        parseInt(size2x6 !== "" ? size2x6 : "0") +
        parseInt(size2x8 !== "" ? size2x8 : "0") +
        parseInt(size2x10 !== "" ? size2x10 : "0") +
        parseInt(size2x12 !== "" ? size2x12 : "0") +
        parseInt(size2x14 !== "" ? size2x14 : "0") +
        parseInt(size3x0 !== "" ? size3x0 : "0");

    var usQty =
        parseInt(size1 !== "" ? size1 : "0") +
        parseInt(size2 !== "" ? size2 : "0") +
        parseInt(size3 !== "" ? size3 : "0") +
        parseInt(size4 !== "" ? size4 : "0") +
        parseInt(size5 !== "" ? size5 : "0") +
        parseInt(size6 !== "" ? size6 : "0") +
        parseInt(size7 !== "" ? size7 : "0") +
        parseInt(size8 !== "" ? size8 : "0");

    const cardValInd = calculate.moq - indianQty;
    const cardValUs = calculate.moq - usQty;

    setTimeout(() => {
        setAnchorElTop(null);
    }, 4000);

    const handleAddCart = () => {
        const mixVal = (prodMix.map((mix) => {
            return mix.id + "-" + mix.sets.pcs + "-" + mix.labourList.map((lab) => {
                return lab.description + "~" + lab.price;
            }) + "-" + mix.plating.description + "~" + mix.plating.price + "-" + mix.metal.metdesc + "~" + mix.metal.metalamt + "," + mix.metalOption.metalgram + "," + mix.metalOption.readygram + "," + mix.metalOption.golwirethaw
        }).join("_"));

        let fData = new FormData();
        fData.append("image1", new File([""], ''));
        fData.append("image2", new File([""], ''));
        fData.append("image3", new File([""], ''));
        fData.append("image4", new File([""], ''));
        fData.append("image5", new File([""], ''));
        fData.append("image6", new File([""], ''));
        fData.append("orgimage1", new File([""], ''));
        fData.append("orgimage2", new File([""], ''));
        fData.append("orgimage3", new File([""], ''));
        fData.append("orgimage4", new File([""], ''));
        fData.append("orgimage5", new File([""], ''));
        fData.append("orgimage6", new File([""], ''));
        fData.append(
            "cart",
            new Blob([
                JSON.stringify({
                    _1x10: selectedEnabled === "a" ? size1x10 ? size1x10 : 0 : size1 ? size1 : 0,
                    _1x12: selectedEnabled === "a" ? size1x12 ? size1x12 : 0 : size2 ? size2 : 0,
                    _1x14: selectedEnabled === "a" ? size1x14 ? size1x14 : 0 : 0,
                    _1x8: selectedEnabled === "a" ? size1x8 ? size1x8 : 0 : 0,
                    _2x0: selectedEnabled === "a" ? size2x0 ? size2x0 : 0 : size3 ? size3 : 0,
                    _2x10: selectedEnabled === "a" ? size2x10 ? size2x10 : 0 : size6 ? size6 : 0,
                    _2x12: selectedEnabled === "a" ? size2x12 ? size2x12 : 0 : size7 ? size7 : 0,
                    _2x14: selectedEnabled === "a" ? size2x14 ? size2x14 : 0 : 0,
                    _2x2: selectedEnabled === "a" ? size2x2 ? size2x2 : 0 : 0,
                    _2x4: selectedEnabled === "a" ? size2x4 ? size2x4 : 0 : size4 ? size4 : 0,
                    _2x6: selectedEnabled === "a" ? size2x6 ? size2x6 : 0 : 0,
                    _2x8: selectedEnabled === "a" ? size2x8 ? size2x8 : 0 : size5 ? size5 : 0,
                    _3x0: selectedEnabled === "a" ? size3x0 ? size3x0 : 0 : size8 ? size8 : 0,
                    box: box?.id + "," + box?.brandname + "," + box?.boxdesc + "," + box?.price + "," + box?.box + "," + box?.pkgcharge + "," + box?.taxincl,
                    cartoncharge: item.cartooncharge,
                    cash_gst: 0,
                    cat: cat?.id + "," + cat?.description,
                    cat2id: labourList[0].id,
                    catSize: catMM?.id + "," + catMM?.description,
                    clientid: userObj.id,
                    ctndescription: item.ctndescription,
                    ctnquantity: item.ctnquantity,
                    description: item.description ? item.description : null,
                    extragram: metOption.extragram,
                    golwirethaw: metOption.golwirethaw,
                    hsncode: 0,
                    id: 0,
                    img1active: imgActive[0].active,
                    img2active: imgActive[1].active,
                    img3active: imgActive[2].active,
                    img4active: imgActive[3].active,
                    img5active: imgActive[4].active,
                    img6active: imgActive[5].active,
                    indiasize: selectedEnabled === "a" ? 1 : 0,
                    itemcode: item.itemcode,
                    labour: labourList.map(i => i.id + "~" + i.description + "~" + i.price + "~" + i.taxincl).toString(),
                    metal: metal?.id + "," + metal?.metdesc + "," + metal?.ligst + "," + metal.metalamt,
                    metalgram: metOption.metalgram,
                    mfgdetails: item.mfgdetails ? item.mfgdetails : null,
                    mfgrate: 0.00,
                    mixmm: item.mixmm ? mixVal : null,
                    mycomment: selectedEnabled === "a" ? indComment ? indComment : null : usComment ? usComment : null,
                    name: userObj.firstname + " " + userObj.lastname,
                    packing: packing?.id + "," + packing?.type + "," + packing?.pkgdesc + "," + packing?.price + "," + packing?.ligst,
                    pid: item.id,
                    plating: plat?.id + "," + plat?.description + "," + plat?.price + "," + plat?.taxincl,
                    profitactive: calculate.profitactive,
                    rate: calculate.cash,
                    readygram: metOption.readygram,
                    sets: sets?.id + "," + sets?.setdesc + "," + sets?.pcs,
                    taxableamt: selectedEnabled === "a" ? (indianQty * calculate.cash).toFixed(2) : (usQty * calculate.cash).toFixed(2),
                    taxincl: calculate.taxincl,
                    total: selectedEnabled === "a" ? indianQty : usQty,
                    userid: item.user.id,
                    ussize: selectedEnabled === "a" ? 0 : 1
                }),
            ])
        );

        // for (var value of fData.values()) {
        //     console.log(value);
        // }

        axios.post(headApi + "/cart", fData)
            .then((res) => {
                // console.log(res.data);
                if (res.data.message === "Data Inserted Successfully !!! ") {
                    setSubmitted(true);
                    alertTypeOps("success");
                    alertMsgOps("Successfully");
                }
            })
    };

    const hideAlert = () => {
        setSubmitted(false);
        // window.location.reload(false);
        history.push("/add-cart")
    };

    return (
        <div className={classes.productPage}>
            <Helmet>
                <title>Order Stock</title>
                <meta
                    name="description"
                    content="Buy your favourite Gold plated bangles Online at best prices in India only at bangles.org. Using online shopping resources also help you compare prices and research products. Our product range includes customised bangles & bracelets, earrings & finger rings, Watches & men’s Sikh Kada and more.  Online shopping is not only convenient, but it can also be a great option for finding the best deals."
                />
                <meta name="keywords" content="Gold plated bangles" />
            </Helmet>
            <Header
                brand="Home"
                links={<HeaderLinks dropdownHoverColor="warning" />}
                fixed
                color="transparent"
                changeColorOnScroll={{
                    height: 100,
                    color: "warning",
                }}
            />
            <Parallax
                image={require("assets/img/bg6.jpg").default}
                filter="dark"
                className={classes.pageHeader}
            >
            </Parallax>
            <div className={classNames(classes.section, classes.sectionGray)}>
                <div className={classes.container}>
                    {submitted ? (
                        <SweetAlert
                            type={alertType}
                            style={{ display: "block", marginTop: "100px" }}
                            title={alertMsg}
                            onConfirm={() => hideAlert()}
                            confirmBtnCssClass={
                                classeAlert.button + " " + classeAlert.success
                            }
                        />
                    ) : null}
                    <div className={classNames(classes.main, classes.mainRaised)}>
                        {!activeSession ? (
                            <GridItem xs={12} sm={6} md={6} lg={6}>
                                <Dialog
                                    classes={{
                                        root: classPop.modalRoot,
                                        paper: classPop.modal + " " + classPop.modalLogin,
                                    }}
                                    open={loginModal}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    // onClose={() => setLoginModal(false)}
                                    aria-labelledby="login-modal-slide-title"
                                    aria-describedby="login-modal-slide-description"
                                >
                                    <Card plain className={classPop.modalLoginCard}>
                                        <DialogTitle
                                            id="login-modal-slide-title"
                                            disableTypography
                                            className={classPop.modalHeader}
                                        >
                                            <CardHeader
                                                plain
                                                color="warning"
                                                className={
                                                    classPop.textCenter + " " + classPop.cardLoginHeader
                                                }
                                            >
                                                <Button
                                                    simple
                                                    className={classPop.modalCloseButton}
                                                    key="close"
                                                    aria-label="Close"
                                                    onClick={() => {
                                                        setLoginModal(false);
                                                        history.push("/")
                                                    }}
                                                >
                                                    {" "}
                                                    <Close className={classPop.modalClose} />
                                                </Button>
                                                <h5 className={classPop.cardTitleWhite}>Log in</h5>
                                            </CardHeader>
                                        </DialogTitle>
                                        <DialogContent
                                            id="login-modal-slide-description"
                                            className={classPop.modalBody}
                                        >
                                            {!otpScreen ? (
                                                <form>
                                                    <p
                                                        className={
                                                            classPop.description + " " + classPop.textCenter
                                                        }
                                                    >
                                                        Welcome
                                                    </p>
                                                    <CardBody className={classPop.cardLoginBody}>
                                                        <PhoneInput
                                                            inputStyle={{
                                                                width: "100%",
                                                                border: "none",
                                                                borderBottom: "1px solid #040000 ",
                                                                borderRadius: "0",
                                                            }}
                                                            country={"in"}
                                                            name="mobile"
                                                            value={mobile}
                                                            onChange={(mobile) => {
                                                                setMobile(
                                                                    mobile.length <= 12 ? mobile : mobile
                                                                );
                                                                setMobileErr("");
                                                            }}
                                                            required={true}
                                                        />
                                                        <span style={{ color: "red" }}>{mobileErr}</span>
                                                    </CardBody>
                                                    <br />
                                                    <div className={classes.textCenter}>
                                                        <Button
                                                            simple
                                                            color="warning"
                                                            size="lg"
                                                            onClick={handleSendOtp}
                                                        >
                                                            Log in
                                                        </Button>
                                                    </div>
                                                    <div className={classes.textCenter}>
                                                        <h6>Already have an account log in to Existing account</h6>
                                                        <Button
                                                            simple
                                                            color="warning"
                                                            size="lg"
                                                            href="/omega-register"
                                                        >
                                                            Create New Account
                                                        </Button>
                                                    </div>
                                                </form>
                                            ) : (
                                                <form>
                                                    <p
                                                        className={
                                                            classPop.description + " " + classPop.textCenter
                                                        }
                                                    >
                                                        6 digit OTP SMS sent on your mobile
                                                    </p>
                                                    <p
                                                        className={
                                                            classPop.description + " " + classPop.textCenter
                                                        }
                                                    >
                                                        {otp}
                                                    </p>
                                                    <CardBody className={classPop.cardLoginBody}>
                                                        <CustomInput
                                                            id="otpNumber"
                                                            formControlProps={{
                                                                fullWidth: true,
                                                            }}
                                                            inputProps={{
                                                                name: "otpNumber",
                                                                value: otpNumber,
                                                                onChange: (e) => handleOtp(e),
                                                                placeholder: "Enter OTP here",
                                                                type: "text",
                                                                // endAdornment: (
                                                                //   <InputAdornment position="end">
                                                                //     <Icon className={classes.inputIconsColor}>
                                                                //       lock_utline
                                                                //     </Icon>
                                                                //   </InputAdornment>
                                                                // ),
                                                                autoComplete: "off",
                                                            }}
                                                            required={true}
                                                        />
                                                        <p
                                                            className={
                                                                classes.description + " " + classes.textCenter
                                                            }
                                                        >
                                                            {otpNumberErr}
                                                        </p>
                                                    </CardBody>
                                                    <div className={classes.textCenter}>
                                                        <Button
                                                            simple
                                                            color="warning"
                                                            size="lg"
                                                            onClick={handleLogin}
                                                        >
                                                            Log in
                                                        </Button>
                                                        <Button
                                                            simple
                                                            color="warning"
                                                            size="lg"
                                                            onClick={handleCall}
                                                        >
                                                            <FiPhoneCall />
                                                            Call Me
                                                        </Button>
                                                    </div>
                                                    <p
                                                        className={
                                                            classes.description + " " + classes.textCenter
                                                        }
                                                    >
                                                        {" "}
                                                        Didn{"'"}t receive the OTP yet?{" "}
                                                        <span style={{ marginLeft: "10px" }}>
                                                            <a href="#" onClick={handleSendOtp}>
                                                                Resend OTP
                                                            </a>
                                                        </span>
                                                    </p>
                                                </form>
                                            )}
                                        </DialogContent>
                                    </Card>
                                </Dialog>
                            </GridItem>
                        ) : (
                            ""
                        )}
                        <GridContainer>
                            <GridItem md={6} sm={6}>
                                <ImageGallery
                                    showFullscreenButton={false}
                                    showPlayButton={false}
                                    startIndex={0}
                                    showIndex={true}
                                    items={images.filter((item) => item.original !== "")}
                                    showThumbnails={true}
                                    renderLeftNav={(onClick, disabled) => {
                                        return (
                                            <button
                                                className="image-gallery-left-nav"
                                                disabled={disabled}
                                                onClick={onClick}
                                            />
                                        );
                                    }}
                                    renderRightNav={(onClick, disabled) => {
                                        return (
                                            <button
                                                className="image-gallery-right-nav"
                                                disabled={disabled}
                                                onClick={onClick}
                                            />
                                        );
                                    }}
                                />
                            </GridItem>
                            <GridItem md={6} sm={12} xs={12}>
                                <GridContainer>
                                    <GridItem md={6} sm={6} xs={12}>
                                        <span className={classes.mainPrice}>
                                            <strong>
                                                Item Code: {item.itemcode + "-" + item.id}
                                            </strong>
                                        </span>
                                    </GridItem>
                                    <GridItem md={6} sm={6} xs={12}>
                                        <span className={classes.mainPrice}>
                                            <strong>
                                                ₹{" "}
                                                {activeSession
                                                    ? Number(calculate.cash).toFixed(2) + "/-"
                                                    : Number(calculate.taxincl).toFixed(2) + "/-"}{" "}
                                                {!activeSession ? (
                                                    calculate.profitactive === 1 ? (
                                                        calculate.cash_gst === 1 ? (
                                                            <h5>
                                                                <span style={{ color: "red" }}>
                                                                    Sets ₹{" "}
                                                                    {(calculate.taxincl / box.box).toFixed(2)}/-
                                                                </span>
                                                            </h5>
                                                        ) : (
                                                            <h5>
                                                                Sets{" "}
                                                                <span>
                                                                    ₹ {(calculate.cash / box.box).toFixed(2)}/-*
                                                                </span>
                                                            </h5>
                                                        )
                                                    ) : calculate.cash_gst === 1 ? (
                                                        <h5>
                                                            <span style={{ color: "#9c27b0" }}>
                                                                Sets ₹{" "}
                                                                {(calculate.taxincl / box.box).toFixed(2)}/-
                                                            </span>
                                                        </h5>
                                                    ) : (
                                                        <h5>
                                                            Sets{" "}
                                                            <span>
                                                                ₹ {(calculate.cash / box.box).toFixed(2)}/-*
                                                            </span>
                                                        </h5>
                                                    )
                                                ) : (
                                                    ""
                                                )}
                                            </strong>
                                        </span>
                                        <span className={classes.mainPrice}>
                                            {activeSession ? (
                                                calculate.profitactive === 1 ? (
                                                    <h5>
                                                        Sets ₹ {(calculate.cash / box.box).toFixed(2)}/-*
                                                    </h5>
                                                ) : (
                                                    <h5>
                                                        Sets ₹ {(calculate.cash / box.box).toFixed(2)}/-*
                                                    </h5>
                                                )
                                            ) : (
                                                ""
                                            )}
                                        </span>
                                    </GridItem>
                                </GridContainer>
                                <h5 className={classes.mainPrice}>
                                    {cat.description +
                                        "/" +
                                        catMM.description +
                                        "/" +
                                        plating.slice(8) +
                                        "/ " +
                                        (metOption.readygram * sets.pcs).toFixed(4) +
                                        " Avg Gram of " +
                                        sets.setdesc +
                                        " and " +
                                        box.boxdesc +
                                        ", " +
                                        item.ctndescription +
                                        ". (" +
                                        packing.type +
                                        ")"}
                                </h5>
                                <h6 className={classes.mainPrice}>
                                    Minimum order: should be for {calculate.moq} Box {"{"}{sets.setdesc +
                                        " and " +
                                        box.boxdesc}{"}"}. Product Delivery
                                    within 25 working days in India Please feel free to Contact Us
                                    @ 022-40128886 {"/"} +91 9967457786 {"/"} +91 996742778
                                </h6>
                                <h5 style={{ backgroundColor: "#ff9800", width: "30%", textAlign: "center", color: "black" }}>{proTotal > 0 ? "In Stock" : "Make To Order"}</h5>
                                <Accordion
                                    active={0}
                                    activeColor="warning"
                                    collapses={[
                                        {
                                            title: "Size Charts",
                                            content: (
                                                <span>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={6} md={6}>
                                                            <div
                                                                className={
                                                                    classe.checkboxAndRadio +
                                                                    " " +
                                                                    classe.checkboxAndRadioHorizontal
                                                                }
                                                            >
                                                                <FormControlLabel
                                                                    control={
                                                                        <Radio
                                                                            checked={selectedEnabled === "a"}
                                                                            onChange={() => setSelectedEnabled("a")}
                                                                            value="a"
                                                                            name="radio button enabled"
                                                                            aria-label="A"
                                                                            icon={
                                                                                <FiberManualRecord
                                                                                    className={classe.radioUnchecked}
                                                                                />
                                                                            }
                                                                            checkedIcon={
                                                                                <FiberManualRecord
                                                                                    className={classe.radioChecked}
                                                                                />
                                                                            }
                                                                            classes={{
                                                                                checked: classe.radio,
                                                                                root: classe.radioRoot,
                                                                            }}
                                                                        />
                                                                    }
                                                                    classes={{
                                                                        label: classe.label,
                                                                        root: classe.labelRoot,
                                                                    }}
                                                                    label="Indian Bangles Size"
                                                                />
                                                            </div>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={6} md={6}>
                                                            <div
                                                                className={
                                                                    classe.checkboxAndRadio +
                                                                    " " +
                                                                    classe.checkboxAndRadioHorizontal
                                                                }
                                                            >
                                                                <FormControlLabel
                                                                    control={
                                                                        <Radio
                                                                            checked={selectedEnabled === "b"}
                                                                            onChange={() => setSelectedEnabled("b")}
                                                                            value="b"
                                                                            name="radio button enabled"
                                                                            aria-label="B"
                                                                            icon={
                                                                                <FiberManualRecord
                                                                                    className={classe.radioUnchecked}
                                                                                />
                                                                            }
                                                                            checkedIcon={
                                                                                <FiberManualRecord
                                                                                    className={classe.radioChecked}
                                                                                />
                                                                            }
                                                                            classes={{
                                                                                checked: classe.radio,
                                                                                root: classe.radioRoot,
                                                                            }}
                                                                        />
                                                                    }
                                                                    classes={{
                                                                        label: classe.label,
                                                                        root: classe.labelRoot,
                                                                    }}
                                                                    label="US Bangles Size"
                                                                />
                                                            </div>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={6}>
                                                            <Tooltip
                                                                id="tooltip-top"
                                                                title="Click Here for Size Chart"
                                                                placement="top"
                                                                classes={{ tooltip: classes.tooltip }}
                                                            >
                                                                {/* <h6 className={classes.title}> */}
                                                                <a href="/insha-bangles-size-guide">
                                                                    Bangles Size Chart
                                                                </a>
                                                                {/* </h6> */}
                                                            </Tooltip>
                                                        </GridItem>
                                                    </GridContainer>

                                                    {selectedEnabled === "a" ? (
                                                        <GridContainer>
                                                            {stockSize1x8 > 0 ?
                                                                <GridItem xs={4} sm={4} md={4}>
                                                                    <CustomInput
                                                                        labelText="1x8"
                                                                        id="1x8"
                                                                        number
                                                                        inputProps={{
                                                                            placeholder: totalPiece,
                                                                            value: size1x8,
                                                                            onChange: (e) => {
                                                                                const { value } = e.target;
                                                                                setSize1x8(value);
                                                                            },
                                                                            onBlur: (e) => {
                                                                                const { value } = e.target;
                                                                                if (value === "") {
                                                                                    setSize1x8("");
                                                                                } else if (value < totalPiece) {
                                                                                    setAnchorElTop(e.currentTarget);
                                                                                    setSize1x8(totalPiece);
                                                                                }
                                                                            },
                                                                        }}
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                    />
                                                                    <Popover
                                                                        classes={{
                                                                            paper: classPop.popover,
                                                                        }}
                                                                        open={Boolean(anchorElTop)}
                                                                        anchorEl={anchorElTop}
                                                                        onClose={() => setAnchorElTop(null)}
                                                                        anchorOrigin={{
                                                                            vertical: "top",
                                                                            horizontal: "center",
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: "bottom",
                                                                            horizontal: "center",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className={
                                                                                classPop.popoverBody +
                                                                                " " +
                                                                                classes.textCenter
                                                                            }
                                                                        >
                                                                            Quantity should be {totalPiece} or greater than {totalPiece} Boxes in Any Size.
                                                                        </div>
                                                                    </Popover>
                                                                </GridItem> : ""}
                                                            {stockSize1x10 > 0 ?
                                                                <GridItem xs={4} sm={4} md={4}>
                                                                    <CustomInput
                                                                        labelText="1x10"
                                                                        id="1x10"
                                                                        number
                                                                        inputProps={{
                                                                            placeholder: totalPiece,
                                                                            value: size1x10,
                                                                            onChange: (e) => {
                                                                                const { value } = e.target;
                                                                                setSize1x10(value);
                                                                            },
                                                                            onBlur: (e) => {
                                                                                const { value } = e.target;
                                                                                if (value === "") {
                                                                                    setSize1x10("");
                                                                                } else if (value < totalPiece) {
                                                                                    setAnchorElTop(e.currentTarget);
                                                                                    setSize1x10(totalPiece);
                                                                                }
                                                                            },
                                                                        }}
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                    />
                                                                </GridItem> : ""}
                                                            {stockSize1x12 > 0 ?
                                                                <GridItem xs={4} sm={4} md={4}>
                                                                    <CustomInput
                                                                        labelText="1x12"
                                                                        id="1x12"
                                                                        number
                                                                        inputProps={{
                                                                            placeholder: totalPiece,
                                                                            value: size1x12,
                                                                            onChange: (e) => {
                                                                                const { value } = e.target;
                                                                                setSize1x12(value);
                                                                            },
                                                                            onBlur: (e) => {
                                                                                const { value } = e.target;
                                                                                if (value === "") {
                                                                                    setSize1x12("");
                                                                                } else if (value < totalPiece) {
                                                                                    setAnchorElTop(e.currentTarget);
                                                                                    setSize1x12(totalPiece);
                                                                                }
                                                                            },
                                                                        }}
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                    />
                                                                </GridItem> : ""}
                                                            {stockSize1x14 > 0 ?
                                                                <GridItem xs={4} sm={4} md={4}>
                                                                    <CustomInput
                                                                        labelText="1x14"
                                                                        id="1x14"
                                                                        number
                                                                        inputProps={{
                                                                            placeholder: totalPiece,
                                                                            value: size1x14,
                                                                            onChange: (e) => {
                                                                                const { value } = e.target;
                                                                                setSize1x14(value);
                                                                            },
                                                                            onBlur: (e) => {
                                                                                const { value } = e.target;
                                                                                if (value === "") {
                                                                                    setSize1x14("");
                                                                                } else if (value < totalPiece) {
                                                                                    setAnchorElTop(e.currentTarget);
                                                                                    setSize1x14(totalPiece);
                                                                                }
                                                                            },
                                                                        }}
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                    />
                                                                </GridItem> : ""}
                                                            {stockSize2x0 > 0 ?
                                                                <GridItem xs={4} sm={4} md={4}>
                                                                    <CustomInput
                                                                        labelText="2x0"
                                                                        id="2x0"
                                                                        number
                                                                        inputProps={{
                                                                            placeholder: totalPiece,
                                                                            value: size2x0,
                                                                            onChange: (e) => {
                                                                                const { value } = e.target;
                                                                                setSize2x0(value);
                                                                            },
                                                                            onBlur: (e) => {
                                                                                const { value } = e.target;
                                                                                if (value === "") {
                                                                                    setSize2x0("");
                                                                                } else if (value < totalPiece) {
                                                                                    setAnchorElTop(e.currentTarget);
                                                                                    setSize2x0(totalPiece);
                                                                                }
                                                                            },
                                                                        }}
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                    />
                                                                </GridItem> : ""}
                                                            {stockSize2x2 > 0 ?
                                                                <GridItem xs={4} sm={4} md={4}>
                                                                    <CustomInput
                                                                        labelText="2x2"
                                                                        id="2x2"
                                                                        number
                                                                        inputProps={{
                                                                            placeholder: totalPiece,
                                                                            value: size2x2,
                                                                            onChange: (e) => {
                                                                                const { value } = e.target;
                                                                                setSize2x2(value);
                                                                            },
                                                                            onBlur: (e) => {
                                                                                const { value } = e.target;
                                                                                if (value === "") {
                                                                                    setSize2x2("");
                                                                                } else if (value < totalPiece) {
                                                                                    setAnchorElTop(e.currentTarget);
                                                                                    setSize2x2(totalPiece);
                                                                                }
                                                                            },
                                                                        }}
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                    />
                                                                </GridItem> : ""}
                                                            {stockSize2x4 > 0 ?
                                                                <GridItem xs={4} sm={4} md={4}>
                                                                    <CustomInput
                                                                        labelText="2x4"
                                                                        id="2x4"
                                                                        number
                                                                        inputProps={{
                                                                            placeholder: totalPiece,
                                                                            value: size2x4,
                                                                            onChange: (e) => {
                                                                                const { value } = e.target;
                                                                                setSize2x4(value);
                                                                            },
                                                                            onBlur: (e) => {
                                                                                const { value } = e.target;
                                                                                if (value === "") {
                                                                                    setSize2x4("");
                                                                                } else if (value < totalPiece) {
                                                                                    setAnchorElTop(e.currentTarget);
                                                                                    setSize2x4(totalPiece);
                                                                                }
                                                                            },
                                                                        }}
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                    />
                                                                </GridItem> : ""}
                                                            {stockSize2x6 > 0 ?
                                                                <GridItem xs={4} sm={4} md={4}>
                                                                    <CustomInput
                                                                        labelText="2x6"
                                                                        id="2x6"
                                                                        number
                                                                        inputProps={{
                                                                            placeholder: totalPiece,
                                                                            value: size2x6,
                                                                            onChange: (e) => {
                                                                                const { value } = e.target;
                                                                                setSize2x6(value);
                                                                            },
                                                                            onBlur: (e) => {
                                                                                const { value } = e.target;
                                                                                if (value === "") {
                                                                                    setSize2x6("");
                                                                                } else if (value < totalPiece) {
                                                                                    setAnchorElTop(e.currentTarget);
                                                                                    setSize2x6(totalPiece);
                                                                                }
                                                                            },
                                                                        }}
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                    />
                                                                </GridItem> : ""}
                                                            {stockSize2x8 > 0 ?
                                                                <GridItem xs={4} sm={4} md={4}>
                                                                    <CustomInput
                                                                        labelText="2x8"
                                                                        id="2x8"
                                                                        number
                                                                        inputProps={{
                                                                            placeholder: totalPiece,
                                                                            value: size2x8,
                                                                            onChange: (e) => {
                                                                                const { value } = e.target;
                                                                                setSize2x8(value);
                                                                            },
                                                                            onBlur: (e) => {
                                                                                const { value } = e.target;
                                                                                if (value === "") {
                                                                                    setSize2x8("");
                                                                                } else if (value < totalPiece) {
                                                                                    setAnchorElTop(e.currentTarget);
                                                                                    setSize2x8(totalPiece);
                                                                                }
                                                                            },
                                                                        }}
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                    />
                                                                </GridItem> : ""}
                                                            {stockSize2x10 > 0 ?
                                                                <GridItem xs={3} sm={3} md={3}>
                                                                    <CustomInput
                                                                        labelText="2x10"
                                                                        id="2x10"
                                                                        number
                                                                        inputProps={{
                                                                            placeholder: totalPiece,
                                                                            value: size2x10,
                                                                            onChange: (e) => {
                                                                                const { value } = e.target;
                                                                                setSize2x10(value);
                                                                            },
                                                                            onBlur: (e) => {
                                                                                const { value } = e.target;
                                                                                if (value === "") {
                                                                                    setSize2x10("");
                                                                                } else if (value < totalPiece) {
                                                                                    setAnchorElTop(e.currentTarget);
                                                                                    setSize2x10(totalPiece);
                                                                                }
                                                                            },
                                                                        }}
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                    />
                                                                </GridItem> : ""}
                                                            {stockSize2x12 > 0 ?
                                                                <GridItem xs={3} sm={3} md={3}>
                                                                    <CustomInput
                                                                        labelText="2x12"
                                                                        id="2x12"
                                                                        number
                                                                        inputProps={{
                                                                            placeholder: totalPiece,
                                                                            value: size2x12,
                                                                            onChange: (e) => {
                                                                                const { value } = e.target;
                                                                                setSize2x12(value);
                                                                            },
                                                                            onBlur: (e) => {
                                                                                const { value } = e.target;
                                                                                if (value === "") {
                                                                                    setSize2x12("");
                                                                                } else if (value < totalPiece) {
                                                                                    setAnchorElTop(e.currentTarget);
                                                                                    setSize2x12(totalPiece);
                                                                                }
                                                                            },
                                                                        }}
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                    />
                                                                </GridItem> : ""}
                                                            {stockSize2x14 > 0 ?
                                                                <GridItem xs={3} sm={3} md={3}>
                                                                    <CustomInput
                                                                        labelText="2x14"
                                                                        id="2x14"
                                                                        number
                                                                        inputProps={{
                                                                            placeholder: totalPiece,
                                                                            value: size2x14,
                                                                            onChange: (e) => {
                                                                                const { value } = e.target;
                                                                                setSize2x14(value);
                                                                            },
                                                                            onBlur: (e) => {
                                                                                const { value } = e.target;
                                                                                if (value === "") {
                                                                                    setSize2x14("");
                                                                                } else if (value < totalPiece) {
                                                                                    setAnchorElTop(e.currentTarget);
                                                                                    setSize2x14(totalPiece);
                                                                                }
                                                                            },
                                                                        }}
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                    />
                                                                </GridItem> : ""}
                                                            {stockSize3x0 > 0 ?
                                                                <GridItem xs={3} sm={3} md={3}>
                                                                    <CustomInput
                                                                        labelText="3x0"
                                                                        id="3x0"
                                                                        number
                                                                        inputProps={{
                                                                            placeholder: totalPiece,
                                                                            value: size3x0,
                                                                            onChange: (e) => {
                                                                                const { value } = e.target;
                                                                                setSize3x0(value);
                                                                            },
                                                                            onBlur: (e) => {
                                                                                const { value } = e.target;
                                                                                if (value === "") {
                                                                                    setSize3x0("");
                                                                                } else if (value < totalPiece) {
                                                                                    setAnchorElTop(e.currentTarget);
                                                                                    setSize3x0(totalPiece);
                                                                                }
                                                                            },
                                                                        }}
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                    />
                                                                </GridItem> : ""}
                                                            <GridItem xs={12} sm={12} md={12}>
                                                                <p style={{ color: "#00acc1" }}>
                                                                    The minimum quantity required {cardValInd} Box
                                                                </p>
                                                                <p style={{ color: "#00acc1" }}>
                                                                    The minimum quantity is {totalPiece} box in one size
                                                                </p>
                                                                <p style={{ color: "red" }}>
                                                                    Total quantity : {indianQty} & Order Value ₹{" "}
                                                                    {selectedEnabled === "a"
                                                                        ? (indianQty * calculate.cash).toFixed(2)
                                                                        : (usQty * calculate.cash).toFixed(2)}
                                                                </p>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={12}>
                                                                <CustomInput
                                                                    labelText="Comment"
                                                                    id="indComment"
                                                                    number
                                                                    inputProps={{
                                                                        placeholder: "Comment",
                                                                        value: indComment,
                                                                        onChange: (e) =>
                                                                            setIndComment(e.target.value),
                                                                    }}
                                                                    formControlProps={{
                                                                        fullWidth: true,
                                                                    }}
                                                                />
                                                            </GridItem>
                                                        </GridContainer>
                                                    ) : (
                                                        <GridContainer>
                                                            {stockSize1x10 > 0 ?
                                                                <GridItem xs={3} sm={3} md={3}>
                                                                    <CustomInput
                                                                        labelText="#1"
                                                                        id="#1"
                                                                        number
                                                                        inputProps={{
                                                                            placeholder: totalPiece,
                                                                            value: size1,
                                                                            onChange: (e) => {
                                                                                const { value } = e.target;
                                                                                setSize1(value);
                                                                            },
                                                                            onBlur: (e) => {
                                                                                const { value } = e.target;
                                                                                if (value === "") {
                                                                                    setSize1("");
                                                                                } else if (value < totalPiece) {
                                                                                    setAnchorElTop(e.currentTarget);
                                                                                    setSize1(totalPiece);
                                                                                }
                                                                            },
                                                                        }}
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                    />
                                                                    <Popover
                                                                        classes={{
                                                                            paper: classPop.popover,
                                                                        }}
                                                                        open={Boolean(anchorElTop)}
                                                                        anchorEl={anchorElTop}
                                                                        onClose={() => setAnchorElTop(null)}
                                                                        anchorOrigin={{
                                                                            vertical: "top",
                                                                            horizontal: "center",
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: "bottom",
                                                                            horizontal: "center",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className={
                                                                                classPop.popoverBody +
                                                                                " " +
                                                                                classes.textCenter
                                                                            }
                                                                        >
                                                                            Quantity should be {totalPiece} or greater than {totalPiece} Boxes in Any Size.
                                                                        </div>
                                                                    </Popover>
                                                                </GridItem> : ""}
                                                            {stockSize1x12 > 0 ?
                                                                <GridItem xs={3} sm={3} md={3}>
                                                                    <CustomInput
                                                                        labelText="#2"
                                                                        id="#2"
                                                                        number
                                                                        inputProps={{
                                                                            placeholder: totalPiece,
                                                                            value: size2,
                                                                            onChange: (e) => {
                                                                                const { value } = e.target;
                                                                                setSize2(value);
                                                                            },
                                                                            onBlur: (e) => {
                                                                                const { value } = e.target;
                                                                                if (value === "") {
                                                                                    setSize2("");
                                                                                } else if (value < totalPiece) {
                                                                                    setAnchorElTop(e.currentTarget);
                                                                                    setSize2(totalPiece);
                                                                                }
                                                                            },
                                                                        }}
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                    />
                                                                </GridItem> : ""}
                                                            {stockSize2x0 > 0 ?
                                                                <GridItem xs={3} sm={3} md={3}>
                                                                    <CustomInput
                                                                        labelText="#3"
                                                                        id="#3"
                                                                        number
                                                                        inputProps={{
                                                                            placeholder: totalPiece,
                                                                            value: size3,
                                                                            onChange: (e) => {
                                                                                const { value } = e.target;
                                                                                setSize3(value);
                                                                            },
                                                                            onBlur: (e) => {
                                                                                const { value } = e.target;
                                                                                if (value === "") {
                                                                                    setSize3("");
                                                                                } else if (value < totalPiece) {
                                                                                    setAnchorElTop(e.currentTarget);
                                                                                    setSize3(totalPiece);
                                                                                }
                                                                            },
                                                                        }}
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                    />
                                                                </GridItem> : ""}
                                                            {stockSize2x4 > 0 ?
                                                                <GridItem xs={3} sm={3} md={3}>
                                                                    <CustomInput
                                                                        labelText="#4"
                                                                        id="#4"
                                                                        number
                                                                        inputProps={{
                                                                            placeholder: totalPiece,
                                                                            value: size4,
                                                                            onChange: (e) => {
                                                                                const { value } = e.target;
                                                                                setSize4(value);
                                                                            },
                                                                            onBlur: (e) => {
                                                                                const { value } = e.target;
                                                                                if (value === "") {
                                                                                    setSize4("");
                                                                                } else if (value < totalPiece) {
                                                                                    setAnchorElTop(e.currentTarget);
                                                                                    setSize4(totalPiece);
                                                                                }
                                                                            },
                                                                        }}
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                    />
                                                                </GridItem> : ""}
                                                            {stockSize2x8 > 0 ?
                                                                <GridItem xs={3} sm={3} md={3}>
                                                                    <CustomInput
                                                                        labelText="#5"
                                                                        id="#5"
                                                                        number
                                                                        inputProps={{
                                                                            placeholder: totalPiece,
                                                                            value: size5,
                                                                            onChange: (e) => {
                                                                                const { value } = e.target;
                                                                                setSize5(value);
                                                                            },
                                                                            onBlur: (e) => {
                                                                                const { value } = e.target;
                                                                                if (value === "") {
                                                                                    setSize5("");
                                                                                } else if (value < totalPiece) {
                                                                                    setAnchorElTop(e.currentTarget);
                                                                                    setSize5(totalPiece);
                                                                                }
                                                                            },
                                                                        }}
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                    />
                                                                </GridItem> : ""}
                                                            {stockSize2x10 > 0 ?
                                                                <GridItem xs={3} sm={3} md={3}>
                                                                    <CustomInput
                                                                        labelText="#6"
                                                                        id="#6"
                                                                        number
                                                                        inputProps={{
                                                                            placeholder: totalPiece,
                                                                            value: size6,
                                                                            onChange: (e) => {
                                                                                const { value } = e.target;
                                                                                setSize6(value);
                                                                            },
                                                                            onBlur: (e) => {
                                                                                const { value } = e.target;
                                                                                if (value === "") {
                                                                                    setSize6("");
                                                                                } else if (value < totalPiece) {
                                                                                    setAnchorElTop(e.currentTarget);
                                                                                    setSize6(totalPiece);
                                                                                }
                                                                            },
                                                                        }}
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                    />
                                                                </GridItem> : ""}
                                                            {stockSize2x12 > 0 ?
                                                                <GridItem xs={3} sm={3} md={3}>
                                                                    <CustomInput
                                                                        labelText="#7"
                                                                        id="#7"
                                                                        number
                                                                        inputProps={{
                                                                            placeholder: totalPiece,
                                                                            value: size7,
                                                                            onChange: (e) => {
                                                                                const { value } = e.target;
                                                                                setSize7(value);
                                                                            },
                                                                            onBlur: (e) => {
                                                                                const { value } = e.target;
                                                                                if (value === "") {
                                                                                    setSize7("");
                                                                                } else if (value < totalPiece) {
                                                                                    setAnchorElTop(e.currentTarget);
                                                                                    setSize7(totalPiece);
                                                                                }
                                                                            },
                                                                        }}
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                    />
                                                                </GridItem> : ""}
                                                            {stockSize3x0 > 0 ?
                                                                <GridItem xs={3} sm={3} md={3}>
                                                                    <CustomInput
                                                                        labelText="#8"
                                                                        id="#8"
                                                                        number
                                                                        inputProps={{
                                                                            placeholder: totalPiece,
                                                                            value: size8,
                                                                            onChange: (e) => {
                                                                                const { value } = e.target;
                                                                                setSize8(value);
                                                                            },
                                                                            onBlur: (e) => {
                                                                                const { value } = e.target;
                                                                                if (value === "") {
                                                                                    setSize8("");
                                                                                } else if (value < totalPiece) {
                                                                                    setAnchorElTop(e.currentTarget);
                                                                                    setSize8(totalPiece);
                                                                                }
                                                                            },
                                                                        }}
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                    />
                                                                </GridItem> : ""}
                                                            <GridItem xs={12} sm={12} md={12}>
                                                                <p style={{ color: "#00acc1" }}>
                                                                    The minimum quantity required {cardValUs} Box
                                                                </p>
                                                                <p style={{ color: "#00acc1" }}>
                                                                    The minimum quantity is {totalPiece} box in one size
                                                                </p>
                                                                <p style={{ color: "red" }}>
                                                                    Total quantity : {usQty} & Order Value ₹{" "}
                                                                    {selectedEnabled === "b"
                                                                        ? (usQty * calculate.cash).toFixed(2)
                                                                        : (indianQty * calculate.cash).toFixed(2)}
                                                                </p>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={12}>
                                                                <CustomInput
                                                                    labelText="Comment"
                                                                    id="comment"
                                                                    number
                                                                    inputProps={{
                                                                        placeholder: "Comment",
                                                                        value: usComment,
                                                                        onChange: (e) =>
                                                                            setUsComment(e.target.value),
                                                                    }}
                                                                    formControlProps={{
                                                                        fullWidth: true,
                                                                    }}
                                                                />
                                                            </GridItem>
                                                        </GridContainer>
                                                    )}
                                                </span>
                                            ),
                                        },
                                        {
                                            title: "Product Details and Care",
                                            content: (
                                                <ul>
                                                    <li>
                                                        Durability only for a period of (6) months from the
                                                        date of sale(Not for spoilage or breakage invalid if
                                                        not stamped).
                                                    </li>
                                                    <li>
                                                        Please wash this piece with soap and warm water &
                                                        wipe it with soft cotton cloth for longer life.
                                                    </li>
                                                </ul>
                                            ),
                                        },
                                    ]}
                                />
                                {/* <GridContainer className={classes.pullRight}>
                  <Button round color="warning" onClick={handleAddCart}>
                    Add to Cart &nbsp; <ShoppingCart />
                  </Button>
                </GridContainer> */}
                                {selectedEnabled === "a" ? calculate.moq < indianQty ?
                                    <GridContainer className={classes.pullRight}>
                                        <Button round color="warning" onClick={handleAddCart}>
                                            Add to Cart &nbsp; <ShoppingCart />
                                        </Button>
                                    </GridContainer> :
                                    <GridContainer className={classes.pullRight}>
                                        <Button round onClick={handleAddCart} disabled={true}>
                                            Add to Cart &nbsp; <ShoppingCart />
                                        </Button>
                                    </GridContainer> : calculate.moq < usQty ?
                                    <GridContainer className={classes.pullRight}>
                                        <Button round color="warning" onClick={handleAddCart}>
                                            Add to Cart &nbsp; <ShoppingCart />
                                        </Button>
                                    </GridContainer> :
                                    <GridContainer className={classes.pullRight}>
                                        <Button round onClick={handleAddCart} disabled={true}>
                                            Add to Cart &nbsp; <ShoppingCart />
                                        </Button>
                                    </GridContainer>}
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}