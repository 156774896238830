import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import TrendingUp from "@material-ui/icons/TrendingUp";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
// import Info from "components/Typography/Info.js";
// import Danger from "components/Typography/Danger.js";
// import Success from "components/Typography/Success.js";

import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";

import cardBlog4 from "assets/img/examples/card-blog4.jpg";
import office2 from "assets/img/office2.jpg";
import blog8 from "assets/img/examples/blog8.jpg";


const useStyles = makeStyles(blogsStyle);

export default function OmegaBlog({ ...rest }) {
    const classes = useStyles();
    return (
        <div className="cd-section" {...rest}>
            <div className={classes.blog}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem
                            xs={12}
                            sm={10}
                            md={10}
                            className={classes.mlAuto + " " + classes.mrAuto}
                        >
                            <h2 className={classes.title} style={{textAlign: "center"}}>Try the exclusive collection that makes you feel heavenly.</h2>
                            <br />
                            <Card plain blog className={classes.card}>
                                <GridContainer>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <CardHeader image plain>
                                            <a href="#pablito" onClick={(e) => e.preventDefault()}>
                                                <img src={cardBlog4} alt="..." />
                                            </a>
                                            <div
                                                className={classes.coloredShadow}
                                                style={{
                                                    backgroundImage: `url(${cardBlog4})`,
                                                    opacity: "1",
                                                }}
                                            />
                                            <div
                                                className={classes.coloredShadow}
                                                style={{
                                                    backgroundImage: `url(${cardBlog4})`,
                                                    opacity: "1",
                                                }}
                                            />
                                        </CardHeader>
                                    </GridItem>
                                    <GridItem xs={12} sm={8} md={8}>
                                        {/* <Info>
                                            <h6 className={classes.cardCategory}>ENTERPRISE</h6>
                                        </Info> */}
                                        <h3 className={classes.cardTitle}>
                                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            The right piece of jewellery will add charm to your look and enlighten your spirit making you never want to part ways with it.
                                            </a>
                                        </h3>
                                        <p className={classes.description}>
                                        Micro Gold plated and Rhodium polish (4MM) 0.048 Avg Gram Of 8 Pcs in 1 Sets and 6 set in 1 Box. Pieces of bangles are all beautiful and unique in their ways. It tends to add more royalty and elegance to a person{"'"}s overall looks and  make an individual feel more beautiful and confident about themselves.
                                        </p>
                                    </GridItem>
                                </GridContainer>
                            </Card>
                            <Card plain blog className={classes.card}>
                                <GridContainer>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <CardHeader image plain>
                                            <a href="#pablito" onClick={(e) => e.preventDefault()}>
                                                <img src={office2} alt="..." />
                                            </a>
                                            <div
                                                className={classes.coloredShadow}
                                                style={{
                                                    backgroundImage: `url(${office2})`,
                                                    opacity: "1",
                                                }}
                                            />
                                            <div
                                                className={classes.coloredShadow}
                                                style={{
                                                    backgroundImage: `url(${office2})`,
                                                    opacity: "1",
                                                }}
                                            />
                                        </CardHeader>
                                    </GridItem>
                                    <GridItem xs={12} sm={8} md={8}>
                                        {/* <Danger>
                                            <h6 className={classes.cardCategory}>
                                                <TrendingUp />
                                                TRENDING
                                            </h6>
                                        </Danger> */}
                                        <h3 className={classes.cardTitle}>
                                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            Designs are intricately seen before that you have never seen.
                                            </a>
                                        </h3>
                                        <p className={classes.description}>
                                        Gold plated (8MM) 0.032 Avg Gram Of 2 Pcs in 1 Sets and 12 set in 1 Box. Each piece of jewellery has its uniqueness and authenticity to it as it is often seen that pieces of jewellery tend to be different for different states and countries; each of its textures and patterns is different from one another, made for customers with different choices and preferences.
                                        </p>
                                        {/* <p className={classes.author}>
                                            by{" "}
                                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                <b>Mike Butcher</b>
                                            </a>{" "}
                                            , 2 days ago
                                        </p> */}
                                    </GridItem>
                                </GridContainer>
                            </Card>
                            <Card plain blog className={classes.card}>
                                <GridContainer>
                                    <GridItem xs={12} sm={4} md={4}>
                                        <CardHeader image plain>
                                            <a href="#pablito" onClick={(e) => e.preventDefault()}>
                                                <img src={blog8} alt="..." />
                                            </a>
                                            <div
                                                className={classes.coloredShadow}
                                                style={{
                                                    backgroundImage: `url(${blog8})`,
                                                    opacity: "1",
                                                }}
                                            />
                                            <div
                                                className={classes.coloredShadow}
                                                style={{
                                                    backgroundImage: `url(${blog8})`,
                                                    opacity: "1",
                                                }}
                                            />
                                        </CardHeader>
                                    </GridItem>
                                    <GridItem xs={12} sm={8} md={8}>
                                        {/* <Success>
                                            <h6 className={classes.cardCategory}>STARTUPS</h6>
                                        </Success> */}
                                        <h3 className={classes.cardTitle}>
                                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            The bangles kindle the aura making you one with all the earthy elements every time you wear them.
                                            </a>
                                        </h3>
                                        <p className={classes.description}>
                                        Gold plated, Golwire medium (3MM) 0.048 Avg Gram Of 8 Pcs in 1 Set and 6 sets in 1 Box. Jewellery is important as it is not only used to elevate one look but also also to express emotions like love and gratitude; every piece of Jewellery ever bought or gifted doesn{"'"}t only have monetary values but also has emotional values added to it.
                                        </p>
                                        {/* <p className={classes.author}>
                                            by{" "}
                                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                <b>Megan Rose</b>
                                            </a>{" "}
                                            , 2 days ago
                                        </p> */}
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </div>
    );
}
