import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { IoLogoWhatsapp } from "react-icons/io";
import { ImMobile } from "react-icons/im";
import { TiPhoneOutline } from "react-icons/ti";
import { headApi } from "utils/headApi";
import { localApi } from "utils/headApi";

import invoiceStyle from "assets/jss/material-kit-pro-react/views/invoiceStyle";

const useStyles = makeStyles(invoiceStyle);

export default function ClientDetails() {
  const [partyDetails, setPartyDetails] = React.useState([]);
  const [regAddress1, setRegAddress1] = React.useState([]);
  const [regAddress2, setRegAddress2] = React.useState([]);
  const [reg, setReg] = React.useState("");
  const classes = useStyles();

  const search = useLocation().search;
  const clientid = new URLSearchParams(search).get("clientid");

  async function getParty() {
    const apiR = localApi + `/register/byMobile/${clientid}`;
    const resultR = await fetch(apiR);
    const getRes = await resultR.json();
    setReg(getRes);
    const api =
      localApi +
      `/regAddress/list/byAdminAndRegisterId/${getRes.aId}/${getRes.id}`;
    const result = await fetch(api);
    const getResult = await result.json();
    setRegAddress1(getResult[0] && getResult[0] ? getResult[0] : "");
    // setRegAddress2(getResult[1] && getResult[1] ? getResult[1] : "");
    setRegAddress2(
      getResult[0] && getResult[0].active === true
        ? getResult[0]
        : getResult[1]
        ? getResult[1]
        : ""
    );
    // setPartyDetails(res);
  }

  useEffect(() => {
    getParty();
  }, []);

  return (
    <div className={classes.productPage}>
      <GridContainer className={classes.borderLine}>
      <GridItem
          md={6}
          sm={6}
          xs={6}
          className={classes.space + " " + classes.verticalLine}
        >
          <span className={classes.invoiceDetailsHead}>Billed to :-</span>
          <br />
          <span className={classes.invoicedetailCom}>
            {regAddress1 && regAddress1.company
              ? regAddress1.company
              : regAddress1 &&
                regAddress1.firstName + " " + regAddress1 &&
                regAddress1.lastName}
          </span>
          <br />
          <span className={classes.invoicedetail}>
            {regAddress1 && regAddress1.plotHouseNo}{" "}
            {regAddress1 && regAddress1.areaColony}
          </span>
          <br />
          <span className={classes.invoicedetail}>
            {regAddress1 && regAddress1.landMark}{" "}
            {regAddress1 && regAddress1.road}{" "}
            {regAddress1 && regAddress1.zipCode}
          </span>{" "}
          <br />
          <span className={classes.invoicedetail}>
            {regAddress1 && regAddress1.city} {regAddress1 && regAddress1.state}
          </span>
          <br />
          <span className={classes.invoicedetails}>
            {regAddress1 && regAddress1.mobile ? <ImMobile /> : ""}{" "}
            {regAddress1 && regAddress1.mobile ? regAddress1.mobile : ""}{" "}
            {regAddress1 && regAddress1.whatsApp ? <IoLogoWhatsapp /> : ""}{" "}
            {regAddress1 && regAddress1.whatsApp ? regAddress1.whatsApp : ""}
          </span>
          <br />
          <span className={classes.invoicedetail + " " + classes.gstIn}>
            {regAddress1 && regAddress1.gstIn ? "GSTIN / UIN" : "Aadhar No."}{" "}
            &nbsp;&nbsp;:{" "}
            {regAddress1 && regAddress1.gstIn
              ? regAddress1.gstIn
              : regAddress1 && regAddress1.aadharNumber}
          </span>
        </GridItem>
        <GridItem md={6} sm={6} xs={6} className={classes.space}>
          <span className={classes.invoiceDetailsHead}>Shipped to :-</span>
          <br />
          <span className={classes.invoicedetailCom}>
            {regAddress2 && regAddress2.company
              ? regAddress2.company
                ? regAddress2.company
                : regAddress2 &&
                  regAddress2.firstName + " " + regAddress2 &&
                  regAddress2.lastName
              : regAddress1.company
              ? regAddress1.company
              : regAddress1 &&
                regAddress1.firstName + " " + regAddress1 &&
                regAddress1.lastName}
          </span>
          <br />
          <span className={classes.invoicedetail}>
            {regAddress2 && regAddress2.plotHouseNo
              ? regAddress2.plotHouseNo
              : regAddress1 && regAddress1.plotHouseNo}{" "}
            {regAddress2 && regAddress2.areaColony
              ? regAddress2.areaColony
              : regAddress1 && regAddress1.areaColony}
          </span>{" "}
          <br />
          <span className={classes.invoicedetail}>
            {regAddress2 && regAddress2.landMark
              ? regAddress2.landMark
              : regAddress1 && regAddress1.landMark}{" "}
            {regAddress2 && regAddress2.road
              ? regAddress2.road
              : regAddress1 && regAddress1.road}{" "}
            {regAddress2 && regAddress2.zipCode
              ? regAddress2.zipCode
              : regAddress1 && regAddress1.zipCode}{" "}
          </span>{" "}
          <br />
          <span className={classes.invoicedetail}>
            {regAddress2 && regAddress2.city
              ? regAddress2.city
              : regAddress1 && regAddress1.city}{" "}
            {regAddress2 && regAddress2.state
              ? regAddress2.state
              : regAddress1 && regAddress1.state}
          </span>
          <br />
          <span className={classes.invoicedetails}>
            {regAddress2 && regAddress2.mobile ? (
              <ImMobile />
            ) : regAddress1 && regAddress1.mobile ? (
              <ImMobile />
            ) : (
              ""
            )}{" "}
            {regAddress2 && regAddress2.mobile
              ? regAddress2.mobile
              : regAddress1 && regAddress1.mobile
              ? regAddress1.mobile
              : ""}{" "}
            {regAddress2 && regAddress2.whatsApp ? (
              <TiPhoneOutline />
            ) : regAddress1 && regAddress1.whatsApp ? (
              <TiPhoneOutline />
            ) : (
              ""
            )}{" "}
            {regAddress2 && regAddress2.whatsApp
              ? regAddress2.whatsApp
              : regAddress1 && regAddress1.whatsApp
              ? regAddress1.whatsApp
              : ""}
          </span>
          <br />
          <span className={classes.invoicedetail + " " + classes.gstIn}>
            {regAddress2 && regAddress2.gstIn
              ? regAddress2.gstIn
                ? "GSTIN / UIN :"
                : "Aadhar No. :"
              : regAddress1.gstIn
              ? "GSTIN / UIN :"
              : "Aadhar No. :"}
            {regAddress2 && regAddress2.gstIn
              ? regAddress2.gstIn
                ? regAddress2.gstIn
                : regAddress2 && regAddress2.aadharNumber
              : regAddress1.gstIn
              ? regAddress1.gstIn
              : regAddress1 && regAddress1.aadharNumber}
          </span>
        </GridItem>
      </GridContainer>
    </div>
  );
}
