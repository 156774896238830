import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { headApi } from "utils/headApi";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { getStates, getCities } from "utils/util";
import Header from "components/Header/Header.js";
// import InshaHeader from "views/InshaBanglesPage/Section/InshaHeader";
// import image from "assets/img/bg7.jpg";
import { localApi } from "utils/headApi";

import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";
import dropDown from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import sweetAlertStyle from "assets/jss/material-kit-pro-react/views/sweetAlretStyle";

const useStyles = makeStyles(signupPageStyle);
const useStyle = makeStyles(dropDown);
const useStyl = makeStyles(sweetAlertStyle);

export default function OmegaRegister({ ...rest }) {
  const [countryList, setCountryList] = React.useState([]);
  const [firstName, setFirstName] = React.useState("");
  const [firstNameErr, setFirstNameErr] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [lastNameErr, setLastNameErr] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [mobileErr, setMobileErr] = React.useState("");
  const [mobileWhatsapp, setMobileWhatsapp] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [companyErr, setCompanyErr] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("in");
  const [cityErr, setCityErr] = React.useState("");
  const [stateCode, setStateCode] = React.useState("");
  const [stateNameErr, setStateNameErr] = React.useState("");
  const [active, setActive] = React.useState(false);
  const [termCondition, setTermConditions] = React.useState(false);
  const [termConditionErr, setTermConditionsErr] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [stateSelect, setStateSelect] = React.useState("");
  const [citySelect, setCitySelect] = React.useState("");
  const [countryName, setCountryName] = React.useState("India");

  const [email, setEmail] = React.useState("");
  const [emailErr, setEmailErr] = React.useState("");
  const [type, setType] = React.useState("");
  const [typeErr, setTypeErr] = React.useState("");
  const [simpleTypeSelect, setSimpleTypeSelect] = React.useState("");
  const [admin, setAdmin] = React.useState("");
  const [btn, setBtn] = React.useState(false);

  // React.useEffect(() => {
  //   window.scrollTo(0, 0);
  //   document.body.scrollTop = 0;
  // });

  const classes = useStyles();
  const classe = useStyle();
  const classSweet = useStyl();
  const history = useHistory();

  async function getType() {
    const api = localApi + `/type/list`;
    const result = await fetch(api);
    const getResult = await result.json();
    // console.log(getResult.LoginType.filter((item) => item.title !== "Control" && item.title !== "Admin"));
    setType(
      getResult && getResult.TypeList.filter(
        (item) => item.title !== "Control" && item.title !== "Admin" && item.title !== "Crm"
      )
    );
    // setType(getResult.LoginType);
    // console.log(getResult);
  }

  useEffect(() => {
    getType();
  }, []);

  async function getAdmin() {
    const api = localApi + `/admin/allList`;
    const result = await fetch(api);
    const getResult = await result.json();
    // console.log(getResult.AdminList);
    setAdmin(getResult && getResult.AdminList);
  }

  useEffect(() => {
    getAdmin();
  }, []);

  async function fetchCountryListCode() {
    const api = localApi + `/countrylistcode/allList`;
    const result = await fetch(api);
    const getResult = await result.json();
    setCountryList(getResult && getResult);
  }

  useEffect(() => {
    fetchCountryListCode();
  }, []);

  const handleMobileNumber = (mobile) => {
    setMobile(mobile);
    fetch(localApi + `/register/find/byMobile/${mobile}`)
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          // setMobileErr(result && result.message);
          if (result.message === "Not Found") {
            setMobileErr("")
          } else {
            setMobileErr(result && result.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleEmail = (e) => {
    const { value } = e.target;
    setEmail(value);
    fetch(localApi + `/register/find/byEmail/${value}`)
      .then((res) => res.json())
      .then(
        (result) => {
          // setEmailErr(result && result.message);
          if (result.message === "Not Found") {
            setEmailErr("")
          } else {
            setEmailErr(result && result.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleCheckedBox = (e) => {
    // console.log(checkboxValue);
    if (e.target?.checked) {
      setMobileWhatsapp(mobile);
    } else {
      setMobileWhatsapp("");
    }
    setCheckboxValue(e.target.checked);
  };

  const handleFirstName = (e) => {
    setFirstName(e.target.value);
    setFirstNameErr("");
  };

  const handleLastName = (e) => {
    setLastName(e.target.value);
    setLastNameErr("");
  };

  const handleCompany = (e) => {
    setCompany(e.target.value);
    setCompanyErr("");
  };

  const handleState = (event) => {
    setStateSelect(event.target.value);
    setStateNameErr("");
  };

  const handleCity = (event) => {
    setCitySelect(event.target.value);
    setCityErr("");
  };

  const handleTypeSimple = (e) => {
    const { value } = e.target;
    setSimpleTypeSelect(value);
    setTypeErr("");
  };

  const Submit = () => {
    const getFind = type.find((f) => f.title === simpleTypeSelect);
    const getFindAdmin = admin.find((f) => f.websitename === "bangles.org.in");
    const countryListCode = countryList.find((f) => f.countryCode.toLowerCase() === countryCode);

    let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;

    // console.log(countryListCode);

    // const data = {
    //   websitename: "bangles.org.in",
    //       typeId: getFind.typeId,
    //       type: getFind.title,
    //       profit: getFind.profit,
    //       firstname: firstName,
    //       lastname: lastName,
    //       mobile: mobile,
    //       whatsapp: mobileWhatsapp,
    //       email: email,
    //       company: company,
    //       city: citySelect.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
    //       state: stateSelect.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
    //       country: countryName.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
    //       smsMarketing: active,
    //       countryCode: countryListCode.countryCode,
    //       isoNumber: countryListCode.isoNumber,
    //       otp: {
    //         otp: 0,
    //         active: 0
    //       },
    // };

    // console.log(JSON.stringify(data));
    // console.log(getFind.profit);
    // console.log(getFindAdmin.id);

    let errorCount = 0;
    if (firstName === "" || !firstName) {
      errorCount++;
      setFirstNameErr("Please Enter First Name");
    }
    if (lastName === "" || !lastName) {
      errorCount++;
      setLastNameErr("Please Enter Last Name");
    }
    if (mobileErr === "Mobile No. Already Registered") {
      errorCount++;
      alertTypeOps("danger");
      alertMsgOps("Number alerady register");
      setSubmitted(true);
    }
    if (emailErr === "Email ID Already Registered") {
      errorCount++;
      alertTypeOps("danger");
      alertMsgOps("Email alerady register");
      setSubmitted(true);
    }
    if (company === "" || !company) {
      errorCount++;
      setCompanyErr("Please Enter Company Name");
    }
    if (citySelect === "" || !citySelect) {
      errorCount++;
      setCityErr("Please Select City Name");
    }
    if (mobile === "" || !mobile || !isValidPhoneNumber(`+${mobile}`)) {
      errorCount++;
      setMobileErr("Please Enter Valid Mobile Number");
    }
    if (email === "" || !email || !emailRegex.test(email)) {
      errorCount++;
      setEmailErr("Please Enter Email Id");
    }
    if (simpleTypeSelect === "" || !simpleTypeSelect) {
      errorCount++;
      setTypeErr("Please Select Type");
    }
    if (stateSelect === "" || !stateSelect) {
      errorCount++;
      setStateNameErr("Please Select State Name");
    }
    if (termCondition === false) {
      errorCount++;
      setTermConditionsErr("Please Select Terms And Conditions");
    }
    if (errorCount === 0) {
      setBtn(true);
      // console.log(firstName);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          websitename: "omegabangles.com",
          typeId: getFind.typeId,
          type: getFind.title,
          profit: getFind.profit,
          firstname: firstName,
          lastname: lastName,
          mobile: mobile,
          whatsapp: mobileWhatsapp,
          email: email,
          company: company,
          city: citySelect.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
          state: stateSelect.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
          country: countryName.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
          smsMarketing: active,
          countryCode: countryListCode.countryCode,
          isoNumber: countryListCode.isoNumber,
          source: "WEBSITE",
          otp: {
            otp: 0,
            active: 0
          },
        }),
      };

      fetch(
        localApi + `/register/add/byAdminId/${getFindAdmin.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Inserted Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Succesfully registerd!");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    // history.push("/bangles-login");
    if (!mobileErr || mobileErr === "") {
      // history.push("/omega-login");
      if (!emailErr || emailErr === "") {
        history.push("/omega-login");
      }
    }
  };

  //   const mystyle = {
  //     paddingLeft: 12,
  //     flex: 1,
  //   };

  return (
    <div>
      <Helmet>
        <title>Omega Sign Up</title>
      </Helmet>
      <Header fixed color="transparent" brand="Home" {...rest} />
      <div className={classes.pageHeader}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <Card className={classes.cardSignup}>
                <h2 className={classes.cardTitle}>Register</h2>
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      {/* <div className={classes.textCenter}>
                        <Button justIcon round color="twitter">
                          <i className={classes.socials + " fab fa-twitter"} />
                        </Button>
                        {` `}
                        <Button justIcon round color="dribbble">
                          <i className={classes.socials + " fab fa-dribbble"} />
                        </Button>
                        {` `}
                        <Button justIcon round color="facebook">
                          <i
                            className={classes.socials + " fab fa-facebook-f"}
                          />
                        </Button>
                        {` `}
                        <h4 className={classes.socialTitle}>or be classical</h4>
                      </div> */}
                      <form className={classes.form}>
                        {submitted ? (
                          <SweetAlert
                            type={alertType}
                            style={{ display: "block", marginTop: "100px" }}
                            title={alertMsg}
                            onConfirm={() => hideAlert()}
                            confirmBtnCssClass={
                              classSweet.button + " " + classSweet.success
                            }
                          />
                        ) : null}
                        <GridContainer>
                          <GridItem xs={12} sm={6} md={6}>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses,
                              }}
                              inputProps={{
                                placeholder: "Enter your First Name...",
                                onChange: (e) => handleFirstName(e),
                                name: "firstName",
                                value: firstName,
                              }}
                              id="firstName"
                              required={true}
                              labelText="First Name"
                            />
                            <span style={{ color: "red", marginLeft: "12px" }}>
                              {firstNameErr}
                            </span>
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6}>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses,
                              }}
                              id="lastName"
                              inputProps={{
                                onChange: (e) => handleLastName(e),
                                value: lastName,
                                name: "lastName",
                                placeholder: "Enter your Last Name...",
                              }}
                              required={true}
                              labelText="Last Name"
                            />
                            <span style={{ color: "red", marginLeft: "12px" }}>
                              {lastNameErr}
                            </span>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <label>Mobile Number</label>
                            <PhoneInput
                              //   style={mystyle}
                              inputStyle={{
                                width: "100%",
                                border: "none",
                                borderBottom: "1px solid #040000 ",
                                borderRadius: "0",
                              }}
                              country={"in"}
                              name="mobile"
                              value={mobile}
                              onChange={(mobile) => {
                                let country_code =
                                  document.body.querySelector(
                                    "div.flag"
                                  )?.className;
                                country_code =
                                  country_code &&
                                  country_code.replace("flag", "");
                                setCountryCode(country_code.trim());
                                handleMobileNumber(mobile);
                                let country_name = document.body.querySelector('div.selected-flag')?.title
                                setCountryName(country_name.slice(0, -6));
                              }}
                              required={true}
                            />
                            <span style={{ color: "red", marginLeft: "12px" }}>
                              {mobileErr}
                            </span>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <FormControlLabel
                              classes={{
                                root: classes.checkboxLabelControl,
                                label: classes.checkboxLabel,
                              }}
                              control={
                                <Checkbox
                                  checked={checkboxValue || false}
                                  onChange={(e) => handleCheckedBox(e)}
                                  tabIndex={-1}
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot,
                                  }}
                                />
                              }
                              label={
                                <span style={{ color: "#e91e63" }}>
                                  If your WhatsApp Number is same, click on this
                                  checkbox.
                                </span>
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <lebal>WhatsApp Number</lebal>
                            <PhoneInput
                              //   style={mystyle}
                              inputStyle={{
                                width: "100%",
                                border: "none",
                                borderBottom: "1px solid #040000 ",
                                borderRadius: "0",
                              }}
                              country={"in"}
                              name="mobile"
                              value={mobileWhatsapp}
                              onChange={(mobileWhatsapp) => {
                                if (mobile === mobileWhatsapp) {
                                  setCheckboxValue(true);
                                } else {
                                  setCheckboxValue(false);
                                }
                                setMobileWhatsapp(mobileWhatsapp);
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses,
                              }}
                              id="company"
                              inputProps={{
                                onChange: (e) => handleCompany(e),
                                value: company,
                                name: "company",
                                placeholder: "Enter your Company Name...",
                              }}
                              required={true}
                              labelText="Company Name"
                            />
                            <span style={{ color: "red", marginLeft: "12px" }}>
                              {companyErr}
                            </span>
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6}>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses,
                              }}
                              inputProps={{
                                onChange: (e) => handleEmail(e),
                                id: "email",
                                value: email,
                                name: "email",
                                placeholder: "Email...",
                              }}
                              required={true}
                            />
                            <span style={{ color: "red", marginLeft: "12px" }}>
                              {emailErr}
                            </span>
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6}>
                            <FormControl
                              fullWidth
                              className={classe.selectFormControl}
                            >
                              <InputLabel
                                htmlFor="simple-select"
                                className={classe.selectLabel}
                              >
                                Select Type...
                              </InputLabel>

                              <Select
                                MenuProps={{
                                  className: classe.selectMenu,
                                }}
                                classes={{
                                  select: classe.select,
                                }}
                                value={simpleTypeSelect}
                                onChange={handleTypeSimple}
                                required={true}
                                inputProps={{
                                  name: "simpleTypeSelect",
                                  id: "simple-Type-Select",
                                }}
                              >
                                <MenuItem
                                  disabled
                                  classes={{
                                    root: classe.selectMenuItem,
                                  }}
                                >
                                  Select Type
                                </MenuItem>
                                {type &&
                                  type.map((item) => (
                                    <MenuItem
                                      key={item.title}
                                      value={item.title}
                                      classes={{
                                        root: classe.selectMenuItem,
                                        selected: classe.selectMenuItemSelected,
                                      }}
                                    >
                                      {item.title}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                            <span style={{ color: "red", marginLeft: "12px" }}>
                              {typeErr}
                            </span>
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6}>
                            <FormControl
                              fullWidth
                              className={classe.selectFormControl}
                            >
                              <InputLabel
                                htmlFor="simple-select"
                                className={classe.selectLabel}
                              >
                                Select State
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classe.selectMenu,
                                }}
                                classes={{
                                  select: classe.select,
                                }}
                                value={stateSelect}
                                onChange={handleState}
                                required={true}
                                inputProps={{
                                  name: "simpleSelect",
                                  id: "simple-select",
                                }}
                              >
                                <MenuItem
                                  disabled
                                  classes={{
                                    root: classe.selectMenuItem,
                                  }}
                                >
                                  Select State
                                </MenuItem>
                                {getStates(countryCode).map((item) => (
                                  <MenuItem
                                    key={item.name}
                                    value={item.name}
                                    onClick={() =>
                                      setStateCode(item?.state_code)
                                    }
                                    classes={{
                                      root: classe.selectMenuItem,
                                      selected: classe.selectMenuItemSelected,
                                    }}
                                  >
                                    {item.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <span style={{ color: "red", marginLeft: "12px" }}>
                              {stateNameErr}
                            </span>
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6}>
                            <FormControl
                              fullWidth
                              className={classe.selectFormControl}
                            >
                              <InputLabel
                                htmlFor="simple-select"
                                className={classe.selectLabel}
                              >
                                Select City
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classe.selectMenu,
                                }}
                                classes={{
                                  select: classe.select,
                                }}
                                value={citySelect}
                                onChange={handleCity}
                                required={true}
                                inputProps={{
                                  name: "simpleSelect",
                                  id: "simple-select",
                                }}
                              >
                                <MenuItem
                                  disabled
                                  classes={{
                                    root: classe.selectMenuItem,
                                  }}
                                >
                                  Select City
                                </MenuItem>
                                {getCities(countryCode, stateCode).map(
                                  (item) => (
                                    <MenuItem
                                      key={item.name}
                                      value={item.name}
                                      onClick={() =>
                                        setStateCode(item?.state_code)
                                      }
                                      classes={{
                                        root: classe.selectMenuItem,
                                        selected: classe.selectMenuItemSelected,
                                      }}
                                    >
                                      {item.name}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                            <span style={{ color: "red", marginLeft: "12px" }}>
                              {cityErr}
                            </span>
                          </GridItem>
                        </GridContainer>
                        <br />
                        <FormControlLabel
                          classes={{
                            root: classes.checkboxLabelControl,
                            label: classes.checkboxLabel,
                          }}
                          control={
                            <Checkbox
                              id="active"
                              value={active}
                              onChange={(e) => {
                                setActive(e.target.checked);
                              }}
                              tabIndex={-1}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                          }
                          label={
                            <span style={{ color: "#e91e63" }}>
                              I would like to recieve the latest news from the
                              Company by phone/whatsapp
                            </span>
                          }
                        />
                        <br />
                        <br />
                        <FormControlLabel
                          required={true}
                          classes={{
                            root: classes.checkboxLabelControl,
                            label: classes.checkboxLabel,
                          }}
                          control={
                            <Checkbox
                              id="active"
                              checked={termCondition || false}
                              // value={termCondition}
                              onChange={(e) => {
                                setTermConditions(e.target.checked);
                                setTermConditionsErr("")
                              }}
                              tabIndex={-1}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                          }
                          label={
                            <span style={{ color: "#e91e63" }}>
                              I agree to the <a href="/terms-and-conditions" target="_blank">Terms & Conditions</a> * and <a href="/privacyPolicy" target="_blank">Privacy Policy</a>.
                            </span>
                          }
                        /><br />
                        <span style={{ color: "red", marginLeft: "12px" }}>
                          {termConditionErr}
                        </span>
                        <div className={classes.textCenter}>
                          {btn === true ? (
                            <Button round>Sign Up</Button>
                          ) :
                            <Button round color="warning" onClick={Submit}>
                              Sign up
                            </Button>}
                        </div>
                      </form>
                    </GridItem>
                    <div style={{ fontSize: "16px", marginTop: "7%" }}>
                      <span style={{ textDecoration: "underline" }}>
                        Already an have Register?
                      </span>
                      <Button simple color="warning" size="lg" href="omega-login">
                        Log In
                      </Button>
                    </div>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
