/*eslint-disable*/
import React from "react";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import { SiWhatsapp } from "react-icons/si";
import { MdEmail } from "react-icons/md";
import { ImMobile } from "react-icons/im";
import { Link } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "views/OmegaBangles/Section/OmegaFooter";
import { localApi } from "utils/headApi.js";
import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-kit-pro-react/views/sweetAlretStyle";

import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";

// const CustomSkinMap = () => {
//     const mapRef = React.useRef(null);
//     React.useEffect(() => {
//         let google = window.google;
//         let map = mapRef.current;
//         let lat = "19.1965408";  //19.1965408,72.8275135
//         let lng = "72.8275135";
//         const myLatlng = new google.maps.LatLng(lat, lng);
//         const mapOptions = {
//             zoom: 14,
//             center: myLatlng,
//             scrollwheel: false,
//             zoomControl: true,
//             styles: [
//                 {
//                     featureType: "water",
//                     stylers: [{ saturation: 43 }, { lightness: -11 }, { hue: "#0088ff" }],
//                 },
//                 {
//                     featureType: "road",
//                     elementType: "geometry.fill",
//                     stylers: [
//                         { hue: "#ff0000" },
//                         { saturation: -100 },
//                         { lightness: 99 },
//                     ],
//                 },
//                 {
//                     featureType: "road",
//                     elementType: "geometry.stroke",
//                     stylers: [{ color: "#808080" }, { lightness: 54 }],
//                 },
//                 {
//                     featureType: "landscape.man_made",
//                     elementType: "geometry.fill",
//                     stylers: [{ color: "#ece2d9" }],
//                 },
//                 {
//                     featureType: "poi.park",
//                     elementType: "geometry.fill",
//                     stylers: [{ color: "#ccdca1" }],
//                 },
//                 {
//                     featureType: "road",
//                     elementType: "labels.text.fill",
//                     stylers: [{ color: "#767676" }],
//                 },
//                 {
//                     featureType: "road",
//                     elementType: "labels.text.stroke",
//                     stylers: [{ color: "#ffffff" }],
//                 },
//                 { featureType: "poi", stylers: [{ visibility: "off" }] },
//                 {
//                     featureType: "landscape.natural",
//                     elementType: "geometry.fill",
//                     stylers: [{ visibility: "on" }, { color: "#b8cb93" }],
//                 },
//                 { featureType: "poi.park", stylers: [{ visibility: "on" }] },
//                 {
//                     featureType: "poi.sports_complex",
//                     stylers: [{ visibility: "on" }],
//                 },
//                 { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
//                 {
//                     featureType: "poi.business",
//                     stylers: [{ visibility: "simplified" }],
//                 },
//             ],
//         };

//         map = new google.maps.Map(map, mapOptions);

//         const marker = new google.maps.Marker({
//             position: myLatlng,
//             map: map,
//             animation: google.maps.Animation.DROP,
//             title: "Omega Bangles",
//         });

//         const contentString =
//             '<div class="info-window-content"><h2>Omega bangles</h2>' +
//             "<p>We are one of the top manufacturers and retailers in Maharastra & Karnataka.</p></div>";

//         const infowindow = new google.maps.InfoWindow({
//             content: contentString,
//         });

//         google.maps.event.addListener(marker, "click", function () {
//             infowindow.open(map, marker);
//         });
//     });
//     return (
//         <>
//             <div
//                 style={{ height: `100%`, borderRadius: "6px", overflow: "hidden" }}
//                 ref={mapRef}
//             ></div>
//         </>
//     );
// };

const useStyles = makeStyles(contactUsStyle);
const useStyl = makeStyles(sweetAlertStyle);

export default function OmegaContact() {
  const [firstName, setFirstName] = React.useState("");
  const [firstNameErr, setFirstNameErr] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [mobileErr, setMobileErr] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailErr, setEmailErr] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [messageErr, setMessageErr] = React.useState("");

  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");

  // React.useEffect(() => {
  //   window.scrollTo(0, 0);
  //   document.body.scrollTop = 0;
  // });

  const classes = useStyles();
  const classSweet = useStyl();

  const handleFirstName = (e) => {
    setFirstName(e.target.value);
    setFirstNameErr("");
  };

  const handleMobile = (e) => {
    setMobile(e.target.value);
    setMobileErr("");
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setEmailErr("");
  };

  const handleMessage = (e) => {
    setMessage(e.target.value);
    setMessageErr("");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let errorCount = 0;
    if (firstName === "" || !firstName) {
      errorCount++;
      setFirstNameErr("Please Enter Your Name");
    }
    if (mobile === "" || !mobile) {
      errorCount++;
      setMobileErr("Please Enter Phone Number");
    }
    if (email === "" || !email) {
      errorCount++;
      setEmailErr("Please Enter Email Address");
    }
    if (message === "" || !message) {
      errorCount++;
      setMessageErr("Please Enter Message");
    }
    if (errorCount === 0) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          adminId: "2",
          firstName: firstName,
          mobile: mobile,
          email: email,
          websiteName: "omegabangles.com",
          message: message,
        }),
      };

      fetch(localApi + `/contactus/add`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Inserted Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Succesfully !!!");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  return (
    <div>
      <Helmet>
        <title>Contact Us</title>
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="warning" />}
        fixed
        color="warning"
      />
      {/* <div className={classes.bigMap}>
                <CustomSkinMap />
            </div> */}
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ marginTop: "7%" }}
      >
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <h2 className={classes.title}>Contact us</h2>
            <GridContainer>
              {submitted ? (
                <SweetAlert
                  type={alertType}
                  style={{ display: "block", marginTop: "100px" }}
                  title={alertMsg}
                  onConfirm={() => hideAlert()}
                  confirmBtnCssClass={
                    classSweet.button + " " + classSweet.success
                  }
                />
              ) : null}
              <GridItem md={6} sm={6} className={classes.mlAuto}>
                <InfoArea
                  className={classes.info}
                  title="Office Address"
                  description={
                    <p>
                      Shop no.20, Ashmita Jyoti CHS D10/11 bldg, <br /> Charkop
                      Naka, Marve Road, <br /> Malad West Mumbai-400095 -
                      Maharashtra
                    </p>
                  }
                  icon={PinDrop}
                  iconColor="warning"
                />
                <InfoArea
                  className={classes.info}
                  title="Call us at"
                  description={
                    <p>
                      <a href="tel:+91-2247501787">
                        <ImMobile />
                        +912247501787
                      </a>{" "}
                      <br />{" "}
                      <span>
                        <SiWhatsapp color="green" />{" "}
                        <a href="tel:+91-9967457786">+919967457786</a>
                      </span>{" "}
                      <br />
                      <Link
                        to="javascript:void(0)"
                        onClick={() =>
                          (window.location = "mailto:info@bangles.org.in")
                        }
                      >
                        <span>
                          <MdEmail /> info@omegabangles.com
                        </span>
                      </Link>
                      <br /> Monday – Saturday, <br />
                      10:00 AM to 06:00 PM <br /> Sunday - 10:00 AM to 03:00 PM
                    </p>
                  }
                  icon={Phone}
                  iconColor="warning"
                />
                <a
                  href={`https://api.whatsapp.com/send?phone=919967457786&text=https://omegabangles.com/%20Hello%20I%20am%20Interested%20in%20your%20Product&app_absent=0`}
                >
                  <InfoArea
                    className={classes.infoArea}
                    title="Click on the Whatsapp icon to get connected with us"
                    description=""
                    icon={SiWhatsapp}
                    iconColor="success"
                  />
                </a>
              </GridItem>
              <GridItem md={6} sm={6}>
                <p>
                  Feel free to ask us, If you have any Queries related to our
                  Product.
                  <br />
                  <br />
                </p>
                <form>
                  <CustomInput
                    labelText="Your Name"
                    id="fristName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "fristName",
                      value: firstName,
                      onChange: (e) => handleFirstName(e),
                    }}
                    required={true}
                  />
                  <span style={{ color: "red" }}>{firstNameErr}</span>
                  <CustomInput
                    labelText="Email address"
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "email",
                      value: email,
                      onChange: (e) => handleEmail(e),
                    }}
                    required={true}
                  />
                  <span style={{ color: "red" }}>{emailErr}</span>
                  <CustomInput
                    labelText="Phone Number"
                    id="mobile"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "mobile",
                      value: mobile,
                      onChange: (e) => handleMobile(e),
                    }}
                    required={true}
                  />
                  <span style={{ color: "red" }}>{mobileErr}</span>
                  <CustomInput
                    labelText="Your message"
                    id="message"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 6,
                      name: "message",
                      value: message,
                      onChange: (e) => handleMessage(e),
                    }}
                    required={true}
                  />
                  <span style={{ color: "red" }}>{messageErr}</span>
                  <div className={classes.textCenter}>
                    <Button color="warning" round onClick={onSubmit}>
                      Contact us
                    </Button>
                  </div>
                </form>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
