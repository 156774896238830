import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";

import "assets/scss/material-kit-pro-react.scss?v=1.10.0";

import AboutUsPage from "views/AboutUsPage/AboutUsPage.js";
import ErrorPage from "views/ErrorPage/ErrorPage.js";
import OmegabanglePage from "views/OmegaBangles/OmegaBanglePage.js";
import OmegaRegister from "views/OmegaBangles/Section/OmegaRegister";
import OmegaLogin from "views/OmegaBangles/Section/OmegaLogin";
import EditProfile from "views/OmegaBangles/Profile/EditProfile";
import AddAddress from "views/OmegaBangles/Profile/AddAddress.js";
import updateAddress from "views/OmegaBangles/Profile/UpdateAddress.js";
import GeneratePassword from "views/OmegaBangles/Profile/GeneratePassword";
import Cart from "views/OmegaBangles/Cart/Cart";
import OmegaBanglesSizeChart from "views/OmegaBangles/Section/OmegaBanglesSizeChart";
import OmegaProductList from "views/OmegaBangles/Section/OmegaProductList";
import OmegaSizeMMList from "views/OmegaBangles/Section/OmegaSizeMMList";
import OmegaProduct from "views/OmegaBangles/Section/OmegaProduct";
import Contact from "views/OmegaBangles/Section/Contact";
import OmegaOffProductList from "views/OmegaBangles/Section/OmegaOffProductList";
import MakingCharge from "views/OmegaBangles/Section/MakingCharge";
import AddCart from "views/OmegaBangles/Cart/AddCart";
import CartShippingAddress from "views/OmegaBangles/Cart/CartShippingAddress";
import CartView from "views/OmegaBangles/Cart/CartView";
import MyOrder from "views/OmegaBangles/Cart/MyOrder";
import OrderView from "views/OmegaBangles/Cart/OrderView";
import OrderViewPrint from "views/OmegaBangles/Cart/OrderViewPrint";
import PaymentRecieved from "views/OmegaBangles/Cart/PaymentRecieved";
import PrivacyPolicy from "views/OmegaBangles/Section/PrivacyPolicy";
import TermsCondition from "views/OmegaBangles/Section/Term&Condition";
import ReturnPolicy from "views/OmegaBangles/Section/ReturnPolicy";
import ReturnAmount from "views/OmegaBangles/Section/ReturnAmount";
import RefundPolicy from "views/OmegaBangles/Section/RefundPolicy";
import CareerField from "views/OmegaBangles/Section/CareerField";
import License from "views/OmegaBangles/Section/License";
import ProductStock from "views/OmegaBangles/Section/ProductStock.js"
import OrderStock from "views/OmegaBangles/Section/OrderStock";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/about-us" component={AboutUsPage} />
      <Route path="/return" component={ReturnAmount} />
      <Route path="/error-page" component={ErrorPage} />
      <Route path="/bangles-product" component={OmegaProduct} />
      <Route path="/size-mm-list" component={OmegaSizeMMList} />
      <Route path="/product-list" component={OmegaProductList} />
      <Route path="/bangles-size-chart" component={OmegaBanglesSizeChart} />
      <Route path="/cart" component={Cart} />
      <Route path="/add-cart" component={AddCart} />
      <Route path ="/cart-shipping-address" component={CartShippingAddress} />
      <Route path="/cart-view" component={CartView} />
      <Route path="/my-order" component={MyOrder} />
      <Route path="/order-view" component={OrderView} />
      <Route path="/order-view-print" component={OrderViewPrint} />
      <Route path="/payment-recieved" component={PaymentRecieved} />
      <Route path="/edit-password" component={GeneratePassword} />
      <Route path="/add-address" component={AddAddress} />
      <Route path="/edit-update-address" component={updateAddress} />
      <Route path="/edit-profile" component={EditProfile} />
      <Route path="/omega-login" component={OmegaLogin} />
      <Route path="/omega-register" component={OmegaRegister} />
      <Route path="/contact-us" component={Contact} />
      <Route path="/offline-product" component={OmegaOffProductList} />
      <Route path="/making-charge" component={MakingCharge} />
      <Route path="/privacyPolicy" component={PrivacyPolicy} />
      <Route path="/terms-and-conditions" component={TermsCondition} />
      <Route path="/return-policy" component={ReturnPolicy} />
      <Route path="/refund-policy" component={RefundPolicy} />
      <Route path="/careers" component={CareerField} />
      <Route path="/license" component={License} />
      <Route path="/todays-deal" component={ProductStock} />
      <Route path="/order-stock" component={OrderStock} />
      <Route path="/" component={OmegabanglePage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
