/* eslint-disable prettier/prettier */
import {
    container,
    mlAuto,
    section,
    main,
    mainRaised,
    title,
    cardTitle,
    grayColor,
    roseColor,
  } from "assets/jss/material-kit-pro-react.js";
  
  import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";
  import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";
  import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.js";
  
  const productStyle = {
    mlAuto,
    main,
    ...imagesStyles,
    ...customSelectStyle,
    ...tooltipsStyle,
    container: {
      ...container,
      zIndex: 1,
    },
    mainRaised: {
      ...mainRaised,
    },
    section: {
      ...section,
      padding: "70px 0px",
    },
    title: {
      ...title,
      marginBottom: 0,
    },
    sectionGray: {
      background: grayColor[14],
    },
    mainPrice: {
      margin: "10px 0px 25px",
    },
    textCenter: {
      textAlign: "center!important",
    },
    textRight: {
      textAlign: "right!important",
    },
    features: {
      paddingTop: "30px",
    },
    productPage: {
      "& $mainRaised": {
        margin: "-40vh 0 0",
        padding: "40px",
      },
      "& .image-gallery-slide img": {
        borderRadius: "3px",
        maxWidth: "300px",
        height: "auto",
      },
      "& .image-gallery-swipe": {
        margin: "30px 0px",
        overflow: "hidden",
        width: "100%",
        height: "auto",
        textAlign: "center",
      },
      "& .image-gallery-thumbnails > .image-gallery-thumbnails-container .image-gallery-thumbnail":
        {
          "&.active > .image-gallery-thumbnail-inner": {
            opacity: "1",
            borderColor: grayColor[6],
          },
          "& > .image-gallery-thumbnail-inner": {
            width: "80%",
            maxWidth: "85px",
            margin: "0 auto",
            padding: "8px",
            display: "block",
            border: "1px solid transparent",
            background: "transparent",
            borderRadius: "3px",
            opacity: ".8",
          },
          "& > .image-gallery-thumbnail-inner img": {
            borderRadius: "3px",
            width: "100%",
            height: "auto",
            textAlign: "center",
          },
        },
    },
    titleRow: {
      marginTop: "-8vh",
    },
    floatRight: {
      float: "right!important",
    },
    headLeft: {
      float: "left !important",
    },
    pageHeader: {
      minHeight: "60vh",
      maxHeight: "600px",
      height: "auto",
      backgroundPosition: "top center",
    },
    descParagragh: {
      fontWeight: "bold",
      fontFamily: "Tahoma",
      color: "#000000",
      fontSize: "12px",
    },
    relatedProducts: {
      marginTop: "50px",
      "& $title": {
        marginBottom: "80px",
      },
    },
    pickSize: {
      marginTop: "50px",
    },
    pullRight: {
      float: "right",
    },
    cardCategory: {
      textAlign: "center",
      marginTop: "10px",
    },
    cardTitle: {
      ...cardTitle,
      textAlign: "center",
    },
    cardDescription: {
      textAlign: "center",
      color: grayColor[0],
      fontSize: "12px",
    },
    invoiceName: {
      textAlign: "right",
    },
    tableHead: {
      fontWeight: "bold",
      fontFamily: "Tahoma",
      color: "#000000",
      fontSize: "10px", //14
    },
    tableTail: {
      fontWeight: "bold",
      fontFamily: "Tahoma",
      color: "#000000",
      fontSize: "14px",
    },
    listDescription: {
      fontWeight: "bold",
      fontFamily: "Tahoma",
      color: "#000000",
      fontSize: "12px",
    },
    // listDescriptions: {
    //     fontWeight: "300",
    //     // fontFamily: "Tahoma",
    //     // color: "#000000",
    //     fontSize: "12px",
    // },
    listDescriptions: {
      fontWeight: "bold",
      fontFamily: "Tahoma",
      color: "#000000",
      fontSize: "8px", //10
      transform: "scaleY(1.5)",
    },
    descParagragh: {
      fontWeight: "bold",
      fontFamily: "Tahoma",
      color: "#000000",
      fontSize: "12px",
    },
    listDescriptionMM: {
      fontWeight: "bold",
      fontFamily: "Tahoma",
      color: "#000000",
      fontSize: "8px", //12
    },
    listNumber: {
      fontWeight: "bold",
      fontFamily: "Tahoma",
      color: "#000000",
      fontSize: "8px", //12
      transform: "scaleY(1.5)",
    },
    sizePadding: {
      marginTop: "3px", //5
      // textAlign: "center",
      marginLeft: "0px",
    },
    sizeSpan: {
      marginRight: "5px",
      marginBottom: "5px",
    },
    headRight: {
      float: "right !important",
    },
    subTotal: {
      marginLeft: "10%",
      fontWeight: "bold",
      fontFamily: "Tahoma",
      color: "#000000",
      fontSize: "12px",
    },
    advance: {
      marginLeft: "12%",
      fontWeight: "bold",
      fontFamily: "Tahoma",
      color: "#000000",
      fontSize: "12px",
    },
    addAdvance: {
      marginLeft: "14%",
      fontWeight: "bold",
      fontFamily: "Tahoma",
      color: "#000000",
      fontSize: "12px",
    },
    boxTl: {
      marginLeft: "28%",
      fontWeight: "bold",
      fontFamily: "Tahoma",
      color: "#000000",
      fontSize: "12px",
    },
    boxTls: {
      marginLeft: "20%",
      fontWeight: "bold",
      fontFamily: "Tahoma",
      color: "#000000",
      fontSize: "12px",
    },
    addboxTls: {
      marginLeft: "19%",
      fontWeight: "bold",
      fontFamily: "Tahoma",
      color: "#000000",
      fontSize: "12px",
    },
    addSubAmount: {
      marginLeft: "43%",
      fontWeight: "bold",
      fontFamily: "Tahoma",
      color: "#000000",
      fontSize: "12px",
    },
    addSubAmounts: {
      marginLeft: "14%",
      fontWeight: "bold",
      fontFamily: "Tahoma",
      color: "#000000",
      fontSize: "12px",
    },
    addSubAmountsV: {
      marginLeft: "14%",
      fontWeight: "bold",
      fontFamily: "Tahoma",
      color: "#000000",
      fontSize: "12px",
    },
    advances: {
      marginLeft: "17%",
      fontWeight: "bold",
      fontFamily: "Tahoma",
      color: "#000000",
      fontSize: "12px",
    },
    addAdvances: {
      marginLeft: "20%",
      fontWeight: "bold",
      fontFamily: "Tahoma",
      color: "#000000",
      fontSize: "12px",
    },
    subAmounts: {
      marginLeft: "16%",
      fontWeight: "bold",
      fontFamily: "Tahoma",
      color: "#000000",
      fontSize: "12px",
    },
    subAmount: {
      marginLeft: "17%",
      fontWeight: "bold",
      fontFamily: "Tahoma",
      color: "#000000",
      fontSize: "12px",
    },
    termDescription: {
      fontWeight: "bold",
      fontFamily: "Tahoma",
      color: "#000000",
      fontSize: "10px", //13
    },
    sizePadding: {
      marginTop: "0px",
      // textAlign: "center",
      marginLeft: "0px",
    },
    taxDesc: {
      fontWeight: "bold",
      fontFamily: "Tahoma",
      color: "#000000",
      fontSize: "7.5px", // 9.7 //14
      width: "auto",
      textAlign: "center",
    },
    invoicedetail: {
      textAlign: "center",
      color: "#000000",
      fontSize: "12px", //15
      fontFamily: "Tahoma",
      textTransform: "capitalize",
    },
    borderLine: {
      padding: "2px",
      borderBottom: "1px solid #000000",
    },
    gstIn: {
      fontWeight: "bold",
    },
    invoicedetailTermAddress: {
      color: "#000000",
      fontSize: "10px", //12
      fontFamily: "Tahoma",
      textTransform: "capitalize",
    },
    spaces: {
      lineHeight: "1.2",
    },
    space: {
      lineHeight: "1.2",
    },
    invoicedetailTerm: {
      color: "#000000",
      fontSize: "10px", //15
      fontFamily: "Tahoma",
      textTransform: "capitalize",
    },
    OrderlistDescription: {
      fontWeight: "bold",
      fontFamily: "Tahoma",
      color: "#000000",
      fontSize: "12px",
    },
    brand: {
      marginTop: "30px",
      marginBottom: "15px",
    },
    imgWidth: {
      width: "0 !important",
      // maxWidth
    },
    space: {
      lineHeight: ".5em",
    },
    textRose: {
      color: roseColor[0],
    },
    justifyContentBetween: {
      justifyContent: "space-between!important",
    },
    socialFeed: {
      "& p": {
        display: "table-cell",
        verticalAlign: "top",
        overflow: "hidden",
        paddingBottom: "10px",
        maxWidth: 300,
      },
      "& i": {
        fontSize: "20px",
        display: "table-cell",
        paddingRight: "10px",
      },
    },
    img: {
      width: "20%",
      marginRight: "5%",
      marginBottom: "5%",
      float: "left",
    },
    block: {
      color: "inherit",
      padding: "0.9375rem",
      fontWeight: "500",
      fontSize: "12px",
      textTransform: "uppercase",
      borderRadius: "3px",
      textDecoration: "none",
      position: "relative",
      display: "block",
    },
    inlineBlock: {
      display: "inline-block",
      padding: "0px",
      width: "auto",
    },
    list: {
      marginBottom: "0",
      padding: "0",
      marginTop: "0",
    },
    left: {
      float: "left!important",
      display: "block",
    },
    right: {
      padding: "15px 0",
      margin: "0",
      float: "right",
    },
    icon: {
      top: "3px",
      width: "18px",
      height: "18px",
      position: "relative",
    },
  
    "@media only screen and (max-width:360px)": {
      container: {
        width: "1108px",
        overflow: "scroll",
        position: "absolute",
      },
      // condition: {
      //   minWidth: "1108px",
      // },
    },
  
    "@media only screen and (max-width:411px)": {
      container: {
        width: "1108px",
        overflow: "scroll",
        position: "absolute",
      },
      // condition: {
      //   minWidth: "1108px",
      // },
    },
  
    "@media only screen and (max-width:414px)": {
      container: {
        width: "1108px",
        overflow: "scroll",
        position: "absolute",
      },
      // condition: {
      //   minWidth: "1108px",
      // },
    },
  
    "@media only screen and (max-width:540px)": {
      container: {
        width: "1108px",
        overflow: "scroll",
        position: "absolute",
      },
      // condition: {
      //   minWidth: "1108px",
      // },
    },
  
    "@media only screen and (max-width:240px)": {
      container: {
        width: "1108px",
        overflow: "scroll",
        position: "absolute",
      },
      // condition: {
      //   minWidth: "1108px",
      // },
    },

    "@media only screen and (max-width:320px)": {
      tableHead: {
        fontWeight: "bold",
        fontFamily: "Tahoma",
        color: "#000000",
        fontSize: "8px", //14
        clear: "both",
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
      listDescription: {
        fontWeight: "bold",
        fontFamily: "Tahoma",
        color: "#000000",
        fontSize: "9px", //12
        padding: "2px 100px 2px 0px",
        // width: "100px"
        clear: "both",
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
      listNumber: {
        fontWeight: "bold",
        fontFamily: "Tahoma",
        color: "#000000",
        fontSize: "8px", //12
        transform: "scaleY(1.5)",
        clear: "both",
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
  
    "@media only screen and (max-width:375px)": {
      tableHead: {
        fontWeight: "bold",
        fontFamily: "Tahoma",
        color: "#000000",
        fontSize: "8px", //14
        clear: "both",
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
      listDescription: {
        fontWeight: "bold",
        fontFamily: "Tahoma",
        color: "#000000",
        fontSize: "9px", //12
        padding: "2px 100px 2px 0px",
        // width: "100px"
        clear: "both",
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
      listNumber: {
        fontWeight: "bold",
        fontFamily: "Tahoma",
        color: "#000000",
        fontSize: "8px", //12
        transform: "scaleY(1.5)",
        clear: "both",
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
  
    "@media only screen and (max-width:414px)": {
      tableHead: {
        fontWeight: "bold",
        fontFamily: "Tahoma",
        color: "#000000",
        fontSize: "8px", //14
        clear: "both",
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
      listDescription: {
        fontWeight: "bold",
        fontFamily: "Tahoma",
        color: "#000000",
        fontSize: "9px", //12
        padding: "2px 100px 2px 0px",
        // width: "100px"
        clear: "both",
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
      listNumber: {
        fontWeight: "bold",
        fontFamily: "Tahoma",
        color: "#000000",
        fontSize: "8px", //12
        transform: "scaleY(1.5)",
        clear: "both",
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
  
    "@media only screen and (max-width:540px)": {
      tableHead: {
        fontWeight: "bold",
        fontFamily: "Tahoma",
        color: "#000000",
        fontSize: "8px", //14
        clear: "both",
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
      listDescription: {
        fontWeight: "bold",
        fontFamily: "Tahoma",
        color: "#000000",
        fontSize: "9px", //12
        padding: "2px 100px 2px 0px",
        // width: "100px"
        clear: "both",
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
      listNumber: {
        fontWeight: "bold",
        fontFamily: "Tahoma",
        color: "#000000",
        fontSize: "8px", //12
        transform: "scaleY(1.5)",
        clear: "both",
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
  
    "@page": {
      size: "a3",
    },
  
    "@media print": {
      container: {
        minWidth: "735px", //1108
        position: "sticky", //sticky
        // pageBreakAfter: "avoid",
        // pageBreakBefore: "avoid",
        // overflow: "initial !important",
        // height: "initial !important",
        // position: "sticky",
        left: "0px",
        right: "0px",
        top: "0px",
        display: "block !important",
        pageBreakBefore: "always",
        // minWidth: "734px",
        // margin: "none"
      },
      print: {
        display: "none",
      },
      // condition: {
      //   minWidth: "1108px",
      // },
    },
  };
  
  export default productStyle;
  