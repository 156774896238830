/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import OmegaFooter from "views/OmegaBangles/Section/OmegaFooter";
import HeaderLinks from "components/Header/HeaderLinks.js";
// import SectionDescription from "views/AboutUsPage/Sections/SectionDescription.js";
import PrivacyDescription from "views/OmegaBangles/Section/PrivacyDescription";
import SectionTeam from "views/AboutUsPage/Sections/SectionTeam.js";
import SectionOffice from "views/AboutUsPage/Sections/SectionOffice.js";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import aboutUsStyl from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(aboutUsStyl);

export default function PrivacyPolicy() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();

  return (
    <div>
      <Helmet>
        <title>Privacy Policy</title>
        <meta
          name="description"
          content="Omega Bangles This Privacy Policy describes how your personal information is collected, used, and save with us When you purchase from us, we'll ask you to provide information including your name, billing address, shipping address, email address, phone number, credit card, To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused."
        />
        <meta name="keywords" content="Omega Bangles Privacy Policy" />
      </Helmet>
      <Header
        brand="Home"
        links={<HeaderLinks dropdownHoverColor="warning" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "warning",
        }}
      />
      <Parallax
        image={require("assets/img/bg2.jpg").default}
        filter="dark"
        small
      >
        <div className={classe.container}>
          <GridContainer justify="center">
            <GridItem
              md={10}
              sm={10}
              className={classNames(
                classe.mlAuto,
                classe.mrAuto,
                classe.textCenter
              )}
            >
              <h1 style={{color: "#fff", fontFamily: "Tahoma", fontWeight: "bold"}}>Privacy Policy</h1>
              <h4 style={{color: "#fff"}}>
              Your satisfaction important to us...
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <PrivacyDescription />
          {/* <SectionTeam /> */}
          {/* <SectionOffice /> */}
          <br />
        </div>
      </div>
      <OmegaFooter />
    </div>
  );
}