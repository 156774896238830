/*eslint-disable*/
import React from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import { FaAdn, FaHome } from "react-icons/fa";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import OmegaService from "./Section/OmegaService.js";
import OmegaProject from "./Section/OmegaProject.js";
import OmegaBlog from "./Section/OmegaBlog.js";
import OmegaContact from "./Section/OmegaContact.js";
import OmegaFooter from "./Section/OmegaFooter.js";

import omegaBanglePage from "assets/jss/material-kit-pro-react/views/blogPostPageStyle.js";

const useStyles = makeStyles(omegaBanglePage);

export default function OmegaBanglePage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const activeSession = sessionStorage.getItem("sessionData");

  return (
    <div>
      <Helmet>
        <title>Omega Bangles</title>
        <meta name="description" content="Omega Manufacture Comapnay" />
        <meta
          name="keywords"
          content="We are one of the top manufacturers and retailers in Maharastra & Karnataka"
        />
      </Helmet>
      <Header
        // brand={<h4><FaHome /> Omega Bangles</h4>}
        brand="Home"
        links={<HeaderLinks dropdownHoverColor="warning" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "warning",
        }}
      />
      <Parallax image={require("assets/img/bg5.jpg").default} filter="dark">
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem md={8} className={classes.textCenter}>
              <h1 className={classes.title}>Omega Bangles</h1>
              <h4 className={classes.subtitle}>
              Bangles from us means bangles forever
              </h4>
              <br />
              {activeSession ? (
                ""
              ) : (
                <div>
                <Button color="warning" round href="/omega-register">
                sign up
              </Button>{" "}
                <Button color="warning" round href="/omega-login">
                  log in
                </Button>
                </div>
              )}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.main}>
        <div className={classes.container}>
          <OmegaService />
          <OmegaProject />
        </div>
      </div>
      <OmegaBlog />
      <OmegaContact />
      <OmegaFooter />
    </div>
  );
}