import statesData from "../statesCities/States.json";
import cityData from "../statesCities/Cities.json";

export function getStates(country_code) {
    return statesData.filter(item => item?.country_code?.toUpperCase() === country_code.toUpperCase())
}

export function getCities(country_code, state_code) {
    // console.log(country_code, state_code);
    return cityData.filter(item => item?.country_code?.toUpperCase() === country_code?.toUpperCase() && item?.state_code?.toUpperCase() === state_code?.toUpperCase())
}